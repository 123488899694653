import React from "react";
import { updateParameterValue } from "../../../store/storyline/actions";
import { RootState } from "../../../store";
import { connect } from "react-redux";

import { Dialog } from "@material-ui/core";
import DialogTitleWithCloseButton from "../../../shared/components/DialogTitleWithCloseButton";

interface ModalProps {
    title: string,
    visibilityParameter: string,
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false,
    children: any,
    parameterValues: Map<string, any>,
    updateParameterValue: typeof updateParameterValue
}

function Modal(props: ModalProps) {
    const { title, visibilityParameter, parameterValues, updateParameterValue, maxWidth, children, ...rest } = props;
    const [isOpen, setIsOpen] = React.useState(false);

    React.useEffect(() => {
        const parameterValue = parameterValues.get(visibilityParameter);
        setIsOpen(!!parameterValue);
    }, [parameterValues, visibilityParameter]);

    const closeDialog = () => {
        setIsOpen(false);
        updateParameterValue(visibilityParameter, false);
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth={maxWidth || "md"}
            onClose={(_, reason) => {
                if (reason !== 'backdropClick') {
                    closeDialog();
                }
            }}
            aria-labelledby="form-dialog-title"
            disablePortal={true}
            {...rest}>
            <DialogTitleWithCloseButton onClose={closeDialog} id="form-dialog-title">
                {title}
            </DialogTitleWithCloseButton>
            {children}
        </Dialog>
    );
}

export default connect(
    (state: RootState) => ({
        parameterValues: state.storyline.parameterValues
    }),
    { updateParameterValue: updateParameterValue as any })(React.memo(Modal));