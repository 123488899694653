import * as api from "../../shared/api-client"

export interface AppState {
    loading: boolean;
    menuItems: api.MenuItemDisplayModel[];
    userMetadata: object;
    menuIsExpanded: boolean;
}

export const LOAD_APP = "LOAD_APP";
export const SHOW_APP = "SHOW_APP";
export const TOGGLE_MENU = "TOGGLE_MENU";

interface LoadAppAction {
    type: typeof LOAD_APP
}

interface ShowAppAction {
    type: typeof SHOW_APP,
    menuItems: api.MenuItemDisplayModel[],
    userMetadata: object
}

interface ToggleMenuAction {
    type: typeof TOGGLE_MENU;
}

export type AppActionTypes = LoadAppAction | ShowAppAction | ToggleMenuAction;