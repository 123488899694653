import React from "react";
import { useAuth0 } from "./AuthContext";
import LoadingScreen from "../shared/components/LoadingScreen";

const RequireAuthentication = ({ children }) => {
    const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

    React.useEffect(() => {
        if (loading || isAuthenticated) {
            return;
        }
        const fn = async () => {
            await loginWithRedirect({
                appState: { targetUrl: window.location }
            });
        };
        fn();
    }, [loading, isAuthenticated, loginWithRedirect]);

    return !loading && isAuthenticated ? (
        <React.Fragment>{children}</React.Fragment>
    ) : (
            <LoadingScreen />
        );
};

export default RequireAuthentication;