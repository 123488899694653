import React from "react";
import NavBar from "../../shared/components/NavBar";
import { Card, Container } from "@material-ui/core";
import MaterialTable from "material-table";
import * as api from "../../shared/api-client";
import * as _ from "lodash";
import EditUserDialog from "./EditUser";
import ConfirmationDialog from "../../shared/components/ConfirmationDialog";
import { connect } from "react-redux";
import { showSuccess, showError } from "../../store/notifications/actions";

function Users(props) {
    const [refreshCounter, setRefreshCounter] = React.useState(0);
    const [data, setData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [editDialogDisplayTrigger, setEditDialogDisplayTrigger] = React.useState(0);
    const [activeItem, setActiveItem] = React.useState(null);
    const [confirmOpen, setConfirmOpen] = React.useState(false);

    React.useEffect(() => {
        setIsLoading(true);
        new api.UsersClient().search()
            .then(result => setData(result))
            .finally(() => setIsLoading(false));
    }, [refreshCounter]);

    const onUserCreatedOrUpdated = () => {
        setRefreshCounter(refreshCounter + 1);
    }

    const createOrEditUser = (model) => {
        setActiveItem(model);
        setEditDialogDisplayTrigger(editDialogDisplayTrigger + 1);
    }

    const deleteUser = (model) => {
        setActiveItem(model);
        setConfirmOpen(true);
    }

    const onDeleteConfirmed = () => {
        new api.UsersClient().delete(activeItem.id)
            .then(() => {
                props.showSuccess("User deleted successfully.");
                setRefreshCounter(refreshCounter + 1);
            })
            .catch(error => {
                props.showError("Unable to delete user.  Please try again.");
            });
    }

    return (
        <>
            <NavBar title="Manage Users" />

            <Container className="plush" maxWidth="lg">
                <Card>

                    <MaterialTable
                        isLoading={isLoading}
                        columns={[
                            { title: 'Name', field: 'name' },
                            { title: 'Email', field: 'emailAddress' },
                            { title: 'Roles', field: 'roles', render: (row) => _.join(_.map(row.roles, r => r.name), ", ") }
                        ]}
                        data={data}
                        actions={[
                            {
                                icon: 'add_circle',
                                tooltip: 'Create User',
                                iconProps: {
                                    classes: { root: "icon-add" }
                                },
                                onClick: (event) => createOrEditUser(null),
                                isFreeAction: true
                            },
                            {
                                icon: 'edit',
                                tooltip: 'Edit User',
                                iconProps: {
                                    classes: { root: "icon-edit" }
                                },
                                onClick: (event, rowData) => createOrEditUser(rowData)
                            },
                            rowData => ({
                                icon: 'delete',
                                iconProps: {
                                    classes: { root: "icon-delete" }
                                },
                                tooltip: 'Delete User',
                                onClick: (event, rowData) => deleteUser(rowData)
                            })
                        ]}
                        title="Users"
                        options={{
                            actionsColumnIndex: -1,
                            pageSize: 10
                        }}
                    />

                </Card>
            </Container>

            <EditUserDialog key={activeItem?.id} displayTrigger={editDialogDisplayTrigger} user={activeItem} onSuccess={onUserCreatedOrUpdated} />

            <ConfirmationDialog
                title="Delete User?"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={onDeleteConfirmed}
            >
                {`Are you sure you want to delete ${activeItem?.name}?`}
            </ConfirmationDialog>

        </>
    );
}

export default connect(
    null,
    { showSuccess, showError })(Users);