import React from "react";
import { Dialog, DialogContent, DialogContentText, DialogActions, Button, Checkbox, FormControlLabel, Select, MenuItem } from "@material-ui/core";
import DialogTitleWithCloseButton from "../../../shared/components/DialogTitleWithCloseButton";
import { Controller, useForm } from "react-hook-form";

const exportFormatOptions = [{
    value: "pdf",
    label: "PDF"
}, {
    value: "pptx",
    label: "PowerPoint"
}];

export type DownloadStorylineOptions = {
    format: string;
    includeTitleBar: boolean;
    includeMinimap: boolean;
    includeCommentary: boolean;
}

interface DownloadStorylineDialogProps {
    onExportClicked: (input: DownloadStorylineOptions) => void;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

export default function DownloadStorylineDialog(props: DownloadStorylineDialogProps) {
    const { onExportClicked, isOpen, setIsOpen } = props;
    const { handleSubmit, control } = useForm<DownloadStorylineOptions>({
        defaultValues: {
            format: "pdf",
            includeTitleBar: true,
            includeMinimap: true,
            includeCommentary: true
        }
    });

    const closeDialog = () => {
        setIsOpen(false);
    }

    const exportClicked = (input: DownloadStorylineOptions) => {
        onExportClicked(input);
        setIsOpen(false);
    };

    return <>
        <Dialog open={isOpen} onClose={closeDialog} disableBackdropClick={true} aria-labelledby="form-dialog-title">
            <DialogTitleWithCloseButton onClose={closeDialog} id="form-dialog-title">
                Export Options
            </DialogTitleWithCloseButton>
            <form onSubmit={handleSubmit(exportClicked)}>
                <DialogContent>
                    <div className="select-header">Export Format:</div>
                    <Controller
                        as={
                            <Select
                                variant="outlined"
                                
                                fullWidth>
                                {
                                    exportFormatOptions.map(o => <MenuItem value={o.value}>{o.label}</MenuItem>)
                                }
                            </Select>
                        }
                        control={control}
                        name="format"
                    />
                    <br />
                    <br />
                    <Controller
                        name="includeTitleBar"
                        control={control}
                        render={p => (
                            <FormControlLabel
                                control={<Checkbox
                                    onChange={e => p.onChange(e.target.checked)}
                                    checked={p.value} />}
                                label="Include Title Bar?"
                            />
                        )}
                    />
                    <br />
                    <Controller
                        name="includeMinimap"
                        control={control}
                        render={p => (
                            <FormControlLabel
                                control={<Checkbox
                                    onChange={e => p.onChange(e.target.checked)}
                                    checked={p.value} />}
                                label="Include Minimap?"
                            />
                        )}
                    />
                    <br />
                    <Controller
                        name="includeCommentary"
                        control={control}
                        render={p => (
                            <FormControlLabel
                                control={<Checkbox
                                    onChange={e => p.onChange(e.target.checked)}
                                    checked={p.value} />}
                                label="Include Commentary?"
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="contained" color="primary">
                        Export
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    </>
}