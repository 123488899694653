import "./Canvas.scss";
import React from 'react';
import Chart from "../components/Chart";
import Annotations from "../components/Annotations";
import Vdt from "../components/Vdt";
import Slider from "../components/Slider";
import Autocomplete from "../components/Autocomplete";
import { Accordion, AccordionGroup } from "../components/Accordion";
import { Radio, RadioGroup } from "../components/RadioGroup";
import Checkbox from "../components/Checkbox";
import TextField from "../components/TextField";
import Tabs from "../components/Tabs";
import TabPanel from "../components/Tabs/TabPanel";
import PersistentTabPanel from "../components/Tabs/PersistentTabPanel";
import FocusContainer from "../components/FocusContainer";
import Gantt from "../components/Gantt";
import Mosaic from "../components/Mosaic";
import DataGrid from "../components/DataGrid";
import Timeline from "../components/Timeline";
import CanvasTour from "../components/CanvasTour";
import Markdown from "../components/Markdown";
import TextToSpeechPlayer from "../components/TextToSpeechPlayer";
import KpiIndicator from "../components/KpiIndicator";
import DatePicker from "../components/DatePicker";
import DateTimePicker from "../components/DateTimePicker";
import ParameterPersister from "../components/ParameterPersister";
import POIEditorMap from "../components/_bespoke/imperial/POIEditorMap";
import ScavengerFeedbackColumn from "../components/_bespoke/imperial/ScavengerFeedbackColumn";
import Map from "../components/Map";
import Modal from "../components/Modal";
import Table, { getColumnNumberFormatter, getColumnDateTimeFormatter } from "../components/Table";
import Split from "../components/Split";
import CanvasEventHandler from "../components/CanvasEventHandler";
import { getIconButtonCellRenderer } from "../components/Table/CellRenderers";
import GeoMapContainer from "../components/GeoMapContainer";
import * as MaterialComponents from "@material-ui/core";
import JsxParser from "react-jsx-parser";
import * as _ from "lodash";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import browserHistory from "../../shared/history";
import { connect } from "react-redux";
import { updateParameterValue, applyParameterValueChanges, refreshDatasourceByName } from "../../store/storyline/actions";
import Style from 'style-it';
import moment from "moment";

import { Template } from "../../store/storyline/types";
import { RootState } from "../../store";

const components = { ...MaterialComponents, Chart, Annotations, Vdt, Slider, Autocomplete, Accordion, AccordionGroup, Radio, RadioGroup, Checkbox, TextField, Split, MaterialTable, Tabs, TabPanel, PersistentTabPanel, Link, FocusContainer, Gantt, Mosaic, DataGrid, Timeline, CanvasTour, Markdown, TextToSpeechPlayer, KpiIndicator, DatePicker, DateTimePicker, ParameterPersister, POIEditorMap, Table, Modal, Map, CanvasEventHandler, GeoMapContainer };
const blacklistedAttrs = [];
const renderError = ({ error }) => <h2 className="home-content error-message">{`An error occurred while parsing template: ${error}`}</h2>;

// JSX Parser doesn't support array indexing inside bindings, so we need to convert arrays to dictionaries, with a concatenation of the property name and item index as key...
function getFlattenedArrays(frameData) {
    return _.chain(frameData)
        .map((val, key) => ({ property: key, value: val }))
        .filter(a => _.isArray(a.value))
        .map((valObject) => _.fromPairs(_.map(valObject.value, (item, index) => ([`${valObject.property}_${index}`, item]))))
        .reduce((acc, val) => ({ ...acc, ...val }), {})
        .value();
}

interface Props {
    template: Template;
    frameData: any;
    parameterValues: Map<string, any>;
    userMetadata: object;
    [key: string]: any;
}

function _Canvas(props: Props) {
    const { template, frameData, navigateTo, updateParameterValueAction, applyParameterValueChangesAction, parameterValues, userMetadata } = props;

    // JSX Parser doesn't support lambdas inside bindings, so we need a higher-order function to close over the slide id and expose a suitable parameterless function that can be bound to...
    const navigateToHandler = React.useCallback((slideId) => {
        return () => navigateTo(slideId);
    }, [navigateTo]);

    const updateParameterValueHandler = React.useCallback((name: string) => {
        return (value) => updateParameterValueAction(name, value);
    }, [updateParameterValueAction]);

    const updateParameterValue = React.useCallback((name: string, value: any) => {
        return () => updateParameterValueAction(name, value);
    }, [updateParameterValueAction]);

    const chain = React.useCallback((actions: Function[]) => {
        return () => _.forEach(actions, f => f());
    }, []);

    const applyParameterValueChanges = React.useCallback(applyParameterValueChangesAction, [applyParameterValueChangesAction]);

    const bindings = {
        ...props, ...frameData, ...getFlattenedArrays(frameData), ...Object.fromEntries(parameterValues), navigateToHandler, updateParameterValueHandler, applyParameterValueChanges, ScavengerFeedbackColumn, getColumnNumberFormatter, getColumnDateTimeFormatter, updateParameterValue, chain, browserHistory, userMetadata, moment, getIconButtonCellRenderer
    }

    const defineFunction = React.useCallback((...args) => {
        return new Function(...args).bind(bindings); // eslint-disable-line no-new-func
    }, [bindings]);

    if (!frameData) return <MaterialComponents.CircularProgress className="loading-spinner" />;

    const result = <div className="jsx-parser">
        <JsxParser
            bindings={{ ...bindings, defineFunction }}
            components={components}
            jsx={template?.contents}
            showWarnings={true}
            disableKeyGeneration={true}
            blacklistedAttrs={blacklistedAttrs}
            renderError={renderError}
            renderInWrapper={false}
        />
    </div>;

    return template?.customCss ?
        Style.it(template.customCss, result) :
        result;
}

const Canvas = connect(
    (state: RootState) => ({
        parameterValues: state.storyline.parameterValues,
        userMetadata: state.app.userMetadata
    }),
    {
        updateParameterValueAction: updateParameterValue,
        applyParameterValueChangesAction: applyParameterValueChanges,
        refreshDatasourceByName

    })(_Canvas);

export default React.memo(Canvas);