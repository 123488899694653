import React from "react";
import "./MiniMap.scss";
import Canvas from "../Canvas/Canvas";
import { StorylineState } from "../../store/storyline/types";
import { navigateTo, goToXYZ } from "../../store/storyline/actions";
import { Fab, Button, Tooltip } from "@material-ui/core";
import { ExpandLess, ExpandMore, ChevronLeft, ChevronRight, ArrowLeft, ArrowRight } from "@material-ui/icons";
import { RootState } from "../../store";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";

function getStyleObjectToCenterSlide(xIndex: number, yIndex: number, xLength: number, yLength: number) {
    const slideWidth = 7.5;
    const slideHeight = 7.5;
    const borderWidth = 0;

    const leftOffset = xIndex;
    const rightOffset = xLength - xIndex - 1;
    const topOffset = yIndex;

    return {
        top: `calc(${-topOffset * slideHeight}vh - ${topOffset * borderWidth * 2}px)`,
        paddingRight: leftOffset > rightOffset ? `${(leftOffset - rightOffset) * slideWidth}vw` : 0,
        paddingLeft: rightOffset > leftOffset ? `${(rightOffset - leftOffset) * slideWidth}vw` : 0
    };
}

function getSlides(storyline: StorylineState) {
    return storyline.slides.map((column, x) =>
        column.map((slide, y) =>
            <Canvas key={`mini-map-slide-${x}-${y}`} template={slide.template} frameData={slide.frames[0]} staticPlot={true} />
        )
    );
}

interface MiniMapProps {
    storyline: StorylineState,
    navigateTo: typeof navigateTo,
    goToXYZ: typeof goToXYZ
}

function MiniMap(props: MiniMapProps) {
    const { storyline } = props;
    const { slides, xIndex, yIndex, frameIndex } = storyline;

    const [state, setState] = React.useState("collapsed");

    const [cachedSlides, setCachedSlides] = React.useState(getSlides(storyline));

    React.useEffect(() => {
        setCachedSlides(getSlides(storyline));
    }, [slides]);

    React.useEffect(() => {
        setTimeout(() => ReactTooltip.rebuild());
    }, [xIndex, yIndex, frameIndex, state]);

    return (
        <>
            <ReactTooltip id="slide-preview" effect="solid" className="tooltip-primary minimap-hover-tooltip" getContent={(dataString) => {
                if (!dataString) return null;
                const [ix, iy, fi] = dataString.split("|").map(a => parseInt(a));
                return <MiniMapFramePreview storyline={storyline} ix={ix} iy={iy} fi={fi} />;
            }} />
            {
                state === "hidden" ?
                    <Tooltip title="Show Navigation" arrow>
                        <Fab color="primary" className="mini-map hidden" onClick={() => setState("collapsed")}>
                            <ExpandLess />
                        </Fab>
                    </Tooltip> :


                    state === "collapsed" ?
                        <div className="mini-map collapsed">

                            <Tooltip title="Hide Navigation" arrow>
                                <div className="navigation-control" id="btn-collapse-minimap">
                                    <Button variant="outlined" color="primary" onClick={() => setState("hidden")}>
                                        <ExpandMore />
                                    </Button>
                                </div>
                            </Tooltip>

                            <Tooltip title="Go to Previous Section" arrow>
                                <div className="navigation-control" id="btn-previous-section">
                                    <Button variant="outlined" color="primary" onClick={() => props.navigateTo("PREVIOUS_SECTION")} disabled={storyline.xIndex === 0 && storyline.yIndex === 0}>
                                        <ArrowLeft />
                                    </Button>
                                </div>
                            </Tooltip>

                            <Tooltip title="Go to Previous Item" arrow>
                                <div className="navigation-control" id="btn-previous-item">
                                    <Button variant="outlined" color="primary" onClick={() => props.navigateTo("PREVIOUS_ITEM")} disabled={storyline.xIndex === 0 && storyline.yIndex === 0 && storyline.frameIndex === 0}>
                                        <ChevronLeft />
                                    </Button>
                                </div>
                            </Tooltip>

                            <div className="slides">
                                <div className="d-flex flex-row page-index">
                                    <span>{`Chapter ${storyline.xIndex + 1} - Page ${storyline.yIndex + 1}${storyline.slides[storyline.xIndex][storyline.yIndex].frames.length > 1 ? "." + (storyline.frameIndex + 1) : ""}`}</span>
                                </div>
                                <div className="container">
                                    <div className="horizontal-container" style={getStyleObjectToCenterSlide(storyline?.xIndex, 0, storyline?.slides?.length, storyline?.slides[storyline?.xIndex]?.length)}>
                                        {storyline?.slides?.map((column, ix) => (
                                            <React.Fragment key={`minimap-column-wrapper-${ix}`}>
                                                {ix > 0 ? <div className="arrow-right" key={`arrow-right-${ix}`}></div> : null}
                                                <div className={`vertical-container ${storyline?.xIndex === ix ? "active" : ""}`} key={`minimap-column-${ix}`}>

                                                    <div key={`minimap-cell-${ix}-${storyline?.xIndex === ix ? storyline?.yIndex : 0}`} className={"slide MuiPaper-root MuiPaper-elevation1 " + (storyline?.xIndex === ix ? "active" : "")} onClick={() => props.goToXYZ(ix, 0, 0)}>

                                                        <div className="tooltip-target" data-for="slide-preview" data-tip={`${ix}|${storyline.xIndex === ix ? storyline?.yIndex : 0}|0`}>
                                                            {cachedSlides[ix][storyline.xIndex === ix ? storyline?.yIndex : 0]}
                                                        </div>

                                                        {
                                                            ix === storyline.xIndex && storyline.slides[storyline.xIndex][storyline.yIndex].frames.length > 1 &&
                                                            <div className="y-index-indicator frame-indicator">
                                                                {
                                                                    storyline.slides[storyline.xIndex][storyline.yIndex].frames.map((frame, fi) =>
                                                                        <div data-for="slide-preview" data-tip={`${storyline.xIndex}|${storyline.yIndex}|${fi}`} className={`item ${fi === storyline.frameIndex ? "active" : ""}`} onClick={(e) => { props.goToXYZ(storyline.xIndex, storyline.yIndex, fi); e.stopPropagation(); }}></div>
                                                                    )
                                                                }
                                                            </div>
                                                        }

                                                    </div>

                                                    {
                                                        column.length > 1 &&
                                                        <div className="y-index-indicator slide-indicator">
                                                            {
                                                                column.map((slide, iy) =>
                                                                    <div data-for="slide-preview" data-tip={`${ix}|${iy}|0`} className={`item ${ix === storyline.xIndex && iy === storyline.yIndex ? "active" : ""}`} onClick={() => props.goToXYZ(ix, iy, 0)}></div>
                                                                )
                                                            }
                                                        </div>
                                                    }

                                                </div>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <Tooltip title="Go to Next Item" arrow>
                                <div className="navigation-control" id="btn-next-item">
                                    <Button variant="outlined" color="primary" onClick={() => props.navigateTo("NEXT_ITEM")} disabled={storyline.xIndex === slides.length - 1 && storyline.yIndex === slides[storyline.xIndex].length - 1 && storyline.frameIndex === slides[storyline.xIndex][storyline.yIndex].frames.length - 1}>
                                        <ChevronRight />
                                    </Button>
                                </div>
                            </Tooltip>

                            <Tooltip title="Go to Next Section" arrow>
                                <div className="navigation-control" id="btn-next-section">
                                    <Button variant="outlined" color="primary" onClick={() => props.navigateTo("NEXT_SECTION")} disabled={storyline.xIndex === slides.length - 1 && storyline.yIndex === slides[storyline.xIndex].length - 1}>
                                        <ArrowRight />
                                    </Button>
                                </div>
                            </Tooltip>

                            <Tooltip title="Expand Navigation" arrow>
                                <div className="navigation-control" id="btn-expand-minimap">
                                    <Button variant="outlined" color="primary" onClick={() => setState("expanded")}>
                                        <ExpandLess />
                                    </Button>
                                </div>
                            </Tooltip>
                        </div> :


                        state === "expanded" ?
                            <div className="mini-map expanded">
                                <div className="slides">
                                    <div className="container">
                                        <div className="horizontal-container" style={getStyleObjectToCenterSlide(storyline?.xIndex, storyline?.yIndex, storyline?.slides?.length, storyline?.slides[storyline?.xIndex]?.length)}>
                                            {storyline?.slides?.map((column, ix) => (
                                                <React.Fragment key={`minimap-column-wrapper-${ix}`}>
                                                    {ix > 0 ? <div className="arrow-right" key={`arrow-right-${ix}`}></div> : null}
                                                    <div className="vertical-container" key={`minimap-column-${ix}`}>
                                                        {column.map((slide, iy) => (
                                                            <React.Fragment key={`minimap-cell-wrapper-${ix}-${iy}`}>
                                                                {iy > 0 ? <div className="arrow-down" key={`arrow-down-${ix}-${iy}`}></div> : <></>}
                                                                <div key={`minimap-cell-${ix}-${iy}`} className={"slide MuiPaper-root MuiPaper-elevation1 " + (storyline?.xIndex === ix && storyline?.yIndex === iy ? "active" : "")} onClick={() => props.goToXYZ(ix, iy, 0)}>

                                                                    <div className="tooltip-target" data-for="slide-preview" data-tip={`${ix}|${iy}|0`}>
                                                                        {cachedSlides[ix][iy]}
                                                                    </div>
                                                                    {
                                                                        storyline.slides[ix][iy].frames.length > 1 &&
                                                                        <div className="y-index-indicator frame-indicator">
                                                                            {
                                                                                storyline.slides[ix][iy].frames.map((frame, fi) =>
                                                                                    <div data-for="slide-preview" data-tip={`${ix}|${iy}|${fi}`} className={`item ${ix === storyline.xIndex && iy === storyline.yIndex && fi === storyline.frameIndex ? "active" : ""}`} onClick={(e) => { props.goToXYZ(ix, iy, fi); e.stopPropagation(); }}></div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>

                                                            </React.Fragment>
                                                        ))}
                                                    </div>
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="expand-collapse">
                                    <Tooltip title="Collapse Navigation" arrow>
                                        <Button variant="outlined" color="primary" onClick={() => setState("collapsed")}>
                                            <ExpandMore />
                                        </Button>
                                    </Tooltip>
                                </div>
                            </div> :
                            <div></div>}
        </>);
}

const MiniMapFramePreview = (props: { storyline: StorylineState, ix: number, iy: number, fi: number }) => {
    const { storyline, ix, iy, fi } = props;
    const template = storyline.slides[ix][iy].template;
    const frameData = storyline.slides[ix][iy].frames[fi];

    return (
        <div className="minimap-hover-tooltip-content">
            <h3>{frameData?.title}</h3>
            <div className="slide">
                <Canvas key={`mini-map-slide-hover-preview-${ix}-${iy}-${fi}`} template={template} frameData={frameData} staticPlot={true} />
            </div>
        </div>
    );
};

export default connect(
    (state: RootState) => ({
    }),
    { navigateTo, goToXYZ })(MiniMap);