import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../store";

interface Props {
    onEnter: () => void;
    onExit: () => void;
    parameterValues: Map<string, any>;
    onParameterValuesChanged: () => void;
}

function CanvasEventHandler(props: Props) {
    const { onEnter, onExit, parameterValues, onParameterValuesChanged } = props;

    React.useEffect(() => {
        onEnter && onEnter();
        return onExit;
    }, []);

    React.useEffect(() => {
        onParameterValuesChanged && onParameterValuesChanged();
    }, [parameterValues, onParameterValuesChanged]);

    return null;
}

export default connect(
    (state: RootState) => ({
        parameterValues: state.storyline.parameterValues
    }),
    null)(CanvasEventHandler);