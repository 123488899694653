import React from "react";
import { render } from "react-dom";
import "./index.scss";
import App from "./App/App";
import * as api from "./shared/api-client"
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import configureStore from "./store";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { Auth0Provider } from "./auth/providers/Auth0Provider";
import { AnonymousAuthProvider } from "./auth/providers/AnonymousAuthProvider";
import history from "./shared/history";
import { SettingsProvider } from "./shared/providers/SettingsProvider";
import { init as initLoggingService } from "./shared/services/loggingService";
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Error from "./shared/components/Error";
import "./shared/monkey-patches";

const store = configureStore();

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
    history.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
};

new api.SettingsClient().getClientSettings().then(settings => {
    const reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: settings.appInsightsInstrumentationKey,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: history }
            }
        }
    });

    appInsights.loadAppInsights();

    // Add the tenant name as a property to each metric...
    appInsights.addTelemetryInitializer((telemetryItem) => {
        telemetryItem.data["tenantName"] = settings.tenantName;
    });

    initLoggingService(appInsights);

    // If the site allows anonymous public access, use the dummy auth provider instead...
    const AuthProvider = settings.allowAnonymousAuthentication ?
        AnonymousAuthProvider : Auth0Provider;

    const appComponent =
        <SettingsProvider settings={settings}>
            <AuthProvider
                domain={settings.auth0?.domain}
                client_id={settings.auth0?.clientId}
                redirect_uri={`${window.location.origin}/login-callback`}
                onRedirectCallback={onRedirectCallback}
                audience={settings.auth0?.audience}
                cacheLocation="localstorage">
                <Provider store={store}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <App />
                    </MuiPickersUtilsProvider>
                </Provider>
            </AuthProvider>
        </SettingsProvider>;

    render(appComponent, document.getElementById("root"));
}).catch(ex => {
    render(<Error message={ex.response} />, document.getElementById("root"))
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
