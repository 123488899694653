import React from "react";
import "./Storyline.scss";
import Canvas from "../Canvas/Canvas";
import MiniMap from "./MiniMap";
import StorylineMenu from "./menu/StorylineMenu";
import { StorylineState } from "../../store/storyline/types";
import { connect } from "react-redux";
import { RootState } from "../../store";
import { navigateTo, goToXYZ, goToID, loadStoryline, updateParameterValue } from "../../store/storyline/actions";
import useQueryParams from "../../shared/hooks/useQueryParams";
import Popout from "react-new-window";
import CanvasDebugger from "../../developer/CanvasDebugger/CanvasDebugger";
import AppInsightsViewTracker from "../../shared/components/AppInsightsViewTracker";
import NavBar from "../../shared/components/NavBar";
import { useAuth0 } from "../../auth/AuthContext";
import { ROLES } from "../../auth/types";

interface StorylineProps {
    id: string,
    storyline: StorylineState,
    userMetadata: object,
    loadStoryline: typeof loadStoryline,
    navigateTo: typeof navigateTo,
    goToXYZ: typeof goToXYZ,
    goToID: typeof goToID,
    updateParameterValue: typeof updateParameterValue
}

export function Storyline(props: StorylineProps) {
    const { storyline, loadStoryline, id, navigateTo, userMetadata, updateParameterValue } = props;
    const [devWindowIsOpen, setDevWindowIsOpen] = React.useState(false);
    const { roles } = useAuth0();
    const toggleDevWindow = () => {
        setDevWindowIsOpen(!devWindowIsOpen);
    }
    const queryParams = useQueryParams();

    if (props.id !== storyline.id) {
        // Remove the "raw value" prefix used to work around the automatic type conversion done by the URL parser...
        for (const [key, value] of Array.from(queryParams?.entries())) {
            if (typeof value === "string" && value.startsWith("\\")) {
                queryParams.set(key, value.substring(1));
            }
        }

        loadStoryline(id, queryParams);
        storyline.loading = true; // loadStoryline call above is async, so there's no guarantee that it will start loading before we hit the render step below...
    }

    React.useEffect(() => {
        const handleKeyUp = (e: KeyboardEvent) => {
            switch (e.key) {
                case "F2":
                case "F9":
                    if (roles?.includes?.(ROLES.DEVELOPER)) {
                        toggleDevWindow();
                    }
                    else {
                        console.log("Current user does not have access to the Canvas Debug window.");
                    }
                    break;
            }

            // We only want to handle global keyboard events here - ignore those from input fields, dropdowns, sliders, etc...
            const target = e.target as Element;
            if (target?.tagName?.toLowerCase() !== "body") return;

            switch (e.key) {
                case "ArrowLeft":
                    if (e.ctrlKey) {
                        navigateTo("PREVIOUS_SECTION");
                    }
                    else {
                        navigateTo("PREVIOUS_ITEM");
                    }
                    break;
                case "ArrowRight":
                    if (e.ctrlKey) {
                        navigateTo("NEXT_SECTION");
                    }
                    else {
                        navigateTo("NEXT_ITEM");
                    }
                    break;
                case "PageUp":
                    navigateTo("PREVIOUS_CHAPTER");
                    break;
                case "PageDown":
                    navigateTo("NEXT_CHAPTER");
                    break;
            }
        }

        document.addEventListener("keyup", handleKeyUp);
        return () => document.removeEventListener("keyup", handleKeyUp);
    }, [navigateTo]);

    React.useEffect(() => {
        // Expose the dispatched "updateParameterValue" to the window object, so that we can call it from outside the app.
        // At the moment this is only used by Puppeteer in order to rehydrate the storyline parameters during export.
        window["updateParameterValue"] = updateParameterValue;
    }, [updateParameterValue]);

    // Hide minimap if we're only displaying a single canvas with a single frame...
    const hideMinimap = storyline?.slides?.length === 1 && storyline?.slides?.[0]?.length === 1 && storyline?.slides?.[0]?.[0]?.frames?.length === 1;

    return (storyline.loading ?
        null :
        storyline?.slides?.length > 0 ?
            <AppInsightsViewTracker key={`storyline-tracker-${storyline?.id}`} componentType="storyline" title={storyline?.name} metadata={{ storylineName: storyline?.name, userMetadata }}>
                <NavBar title={storyline?.currentFrame?.frame?.title} actions={<StorylineMenu />}>
                </NavBar>
                <div className="storyline">
                    <div className="main-canvas">
                        <AppInsightsViewTracker key={`canvas-tracker-${storyline?.xIndex}-${storyline?.yIndex}`} componentType="canvas" title={storyline?.currentFrame?.frame?.title} metadata={{ canvasName: storyline?.slides?.[storyline.xIndex]?.[storyline.yIndex]?.name, storylineName: storyline?.name, userMetadata }}>
                            <Canvas template={storyline?.currentFrame?.template} frameData={storyline?.currentFrame?.frame} navigateTo={props.goToID} staticPlot={false} />
                        </AppInsightsViewTracker>
                    </div>
                    {!hideMinimap && <MiniMap storyline={storyline} />}
                </div>
                {devWindowIsOpen &&
                    <Popout onUnload={toggleDevWindow} url="" title="Canvas Debugger" features={{ width: 1024, height: 768 }}>
                        <CanvasDebugger slide={storyline.slides[storyline.xIndex][storyline.yIndex]} frameData={storyline?.currentFrame?.frame} />
                    </Popout>
                }
            </AppInsightsViewTracker> :
            <h2 className="home-content">Storyline is empty.</h2>
    );
}

export default connect(
    (state: RootState) => ({
        storyline: state.storyline,
        userMetadata: state.app.userMetadata
    }),
    { navigateTo, goToXYZ, goToID, updateParameterValue: updateParameterValue as any })(Storyline);
