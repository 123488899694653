import React from 'react';
import "./Accordion.scss";
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

export function Accordion(props) {
    const [expanded, setExpanded] = React.useState<string | false>(props.expandedGroup);
    const setExpandedWithSupportForCollapsingFinalPanel = (panelName: string) => {
        if (expanded === panelName) {
            setExpanded("");
        } else {
            setExpanded(panelName);
        }
    }

    const renderedChildren = React.Children.map(props.children, (child) =>
        React.cloneElement(child, { expanded, setExpanded: setExpandedWithSupportForCollapsingFinalPanel })
    );

    return (
        <div className="accordion">
            {renderedChildren}
        </div>
    );
}

export function AccordionGroup(props) {
    return (
        <ExpansionPanel expanded={props.expanded === props.title} onChange={() => props.setExpanded(props.title)}>
            <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls={props.title + "-content"}
                id={props.title}
            >
                {props.title}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                {props.children}
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
}