import React from "react";
import {
    ENQUEUE_SNACKBAR,
    CLOSE_SNACKBAR,
    REMOVE_SNACKBAR,
    INCREMENT_OUTSTANDING_REQUEST_COUNT,
    DECREMENT_OUTSTANDING_REQUEST_COUNT
} from "./types";
import { IconButton } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import DetailedErrorSnackMessage from "./DetailedErrorSnackMessage";

export function enqueueSnackbar(notification) {
    return (dispatch) => {
        const key = notification?.options?.key || new Date().getTime() + Math.random();

        dispatch({
            type: ENQUEUE_SNACKBAR,
            notification: {
                ...notification,
                key,
                options: {
                    action: key => (
                        <IconButton size="small" aria-label="close" color="inherit" onClick={() => dispatch(closeSnackbar(key))}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    ),
                    ...notification.options
                }
            },
        });
    }
}

export function closeSnackbar(key) {
    return {
        type: CLOSE_SNACKBAR,
        dismissAll: !key, // dismiss all if no key has been defined
        key,
    }
}

export function removeSnackbar(key) {
    return {
        type: REMOVE_SNACKBAR,
        key,
    }
}

export function showError(errorMessage) {
    return (dispatch) => {
        dispatch({
            type: ENQUEUE_SNACKBAR,
            notification: {
                message: errorMessage,
                key: new Date().getTime() + Math.random(),
                options: {
                    action: key => (
                        <IconButton size="small" aria-label="close" color="inherit" onClick={() => dispatch(closeSnackbar(key))}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    ),
                    variant: 'error',
                    persist: true
                }
            },
        })
    }
}

export function showDetailedError(errorMessage, details = "") {
    return (dispatch) => {
        dispatch({
            type: ENQUEUE_SNACKBAR,
            notification: {
                message: errorMessage,
                details: details,
                key: new Date().getTime() + Math.random(),
                options: {
                    content: (key, message) => <DetailedErrorSnackMessage id={key} message={message} details={details} />,
                    variant: 'error',
                    persist: true
                }
            },
        })
    }
}

export function showSuccess(successMessage) {
    return {
        type: ENQUEUE_SNACKBAR,
        notification: {
            message: successMessage,
            key: new Date().getTime() + Math.random(),
            options: {
                variant: "success"
            }
        }
    };
}

export function incrementOutstandingRequestCount() {
    return {
        type: INCREMENT_OUTSTANDING_REQUEST_COUNT
    }
}

export function decrementOutstandingRequestCount() {
    return {
        type: DECREMENT_OUTSTANDING_REQUEST_COUNT
    }
}