import React from "react";
import "./Markdown.scss";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

export default function Markdown(props: any) {
    const source = props?.source || props?.children;

    return (
        <div className="markdown">
            <ReactMarkdown remarkPlugins={[remarkGfm]} {...props} children={source} />
        </div>
    );
}