import React from 'react';
import { StorylineState } from "../../../store/storyline/types";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { updateParameterValue } from "../../../store/storyline/actions";
import { KeyboardDatePicker } from '@material-ui/pickers'
import { Moment } from 'moment';

interface Props {
    storyline: StorylineState;
    updateParameterValue: typeof updateParameterValue;
    name: string;
    format?: string;
}

export function DatePicker(props: Props) {
    const { storyline, updateParameterValue, name, format, ...other } = props;
    const [value, setValue] = React.useState(storyline.parameterValues.get(name) || null);

    const handleChange = (newValue: Moment) => {
        updateParameterValue(name, newValue);
        setValue(newValue);
    };

    React.useEffect(() => {
        if (storyline.parameterValues.has(name)) {
            const newValue = storyline.parameterValues.get(name);
            if (newValue !== value) {
                setValue(newValue);
            }
        }
    }, [storyline.parameterValues]);

    return (
        <KeyboardDatePicker
            {...other}
            inputVariant="outlined"
            value={value}
            onChange={handleChange}
            format={format || "YYYY/MM/DD"}
            autoOk
        />
    );
}

export default connect(
    (state: RootState) => ({
        storyline: state.storyline
    }),
    { updateParameterValue: updateParameterValue as any })(DatePicker);