import React from "react";
import VisTimeline from 'react-vis-timeline';
import "./Timeline.scss";
import moment from "moment";
import * as _ from "lodash";

export interface Group {
    id: string;
    name: string;
}

export interface Activity {
    id: string;
    name: string;
    color: string;
}

export interface Event {
    id: string;
    groupId: string;
    activityId: string;
    start: Date;
    end: Date;
    message: string;
}

export interface TimelineProps {
    groups?: Group[];
    activities: Activity[];
    events: Event[];
}

const tooltipTemplate = (item, element, data) => {
    return `
<div class="timeline-tooltip">
    <div class="title">${item.title}</div>
    <div class="dates">
        <div class="date"><div class="title">From:</div> <div>${moment(item.start).format("DD MMM YYYY @ HH:mm")}</div></div>
        <div class="date"><div class="title">To:</div> <div>${moment(item.end).format("DD MMM YYYY @ HH:mm")}</div></div>
    </div>
    <div class="message">${item.message || ""}</div>
</div>`;
}

function mapTimelineGroups(groups: Group[]) {
    return groups.map(g => ({ id: g.id, content: g.name }));
}

function mapTimelineItems(events: Event[], activities: Activity[]) {
    const mapEventToItem = (event: Event) => {
        const activity = _.find(activities, a => a.id === event.activityId);

        return {
            ...event,
            group: event.groupId,
            style: `background-color: ${activity?.color}`,
            title: activity?.name,
            content: ""
        };
    };

    return events.map(mapEventToItem);
}

function Timeline(props: { input: TimelineProps, onSelectionChanged: (selectedItems: any[]) => void, [key: string]: any }) {
    const { activities, events, groups } = props.input;
    // The ReactVisTimeline component does not allow for updating an event handler, so a traditional closure approach doesn't work. 
    // The workaround is to use a singleton reference to the selected ids - which we can use inside the click handler...
    const selectedIdsRef = React.useRef([]);
    const [selectedIds, setSelectedIds] = React.useState([]);
    const timelineRef = React.useRef(null);

    React.useEffect(() => {
        if (!timelineRef?.current) return;

        timelineRef.current.timeline.setData({
            groups: mapTimelineGroups(groups),
            items: []
        })

        setTimeout(() => timelineRef.current.timeline.setData({
            groups: mapTimelineGroups(groups),
            items: mapTimelineItems(events, activities)
        }));
    }, [activities, events, groups]);

    const options: any = {
        margin: 0,
        height: "100%",
        stack: false,
        type: 'range',
        selectable: false, // We handle the selection manually via a click handler in order to allow for unselecting an item by clicking on it...
        tooltip: {
            template: tooltipTemplate
        },
        min: _.minBy(events, e => e.start).start,
        max: _.maxBy(events, e => e.end).end,
        showCurrentTime: false
    };


    const clickHandler = (e: { item: string, event: MouseEvent }) => {
        if (!props.onSelectionChanged) return;

        // Multi select
        if (e.event.ctrlKey || e.event.shiftKey) {
            if (_.find(selectedIdsRef.current, i => i === e.item)) {
                _.remove(selectedIdsRef.current, i => i === e.item)
            }
            else {
                selectedIdsRef.current.push(e.item)
            }
        }
        // Single select...
        else {
            if (_.find(selectedIdsRef.current, i => i === e.item)) {
                _.remove(selectedIdsRef.current, i => true);
            }
            else {
                _.remove(selectedIdsRef.current, i => true);
                selectedIdsRef.current.push(e.item)
            }
        }

        setSelectedIds([...selectedIdsRef.current]);

        // Invoke callback...
        props.onSelectionChanged(_.map(selectedIdsRef.current, id => timelineRef.current.timeline.itemSet.items[id].data));
    };

    return (
        <div className="timeline-container">
            <div className="timeline">
                <VisTimeline
                    ref={timelineRef}
                    options={options}
                    clickHandler={clickHandler}
                    selection={selectedIds}
                />
            </div>
            <div className="legend">
                {activities.map(a => (
                    <div className="activity">
                        <div className="color-block" style={{ backgroundColor: a.color }} />
                        <div className="title">{a.name}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default React.memo(Timeline);