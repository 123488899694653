import "./ScavengerFeedbackColumn.scss";
import React from "react";
import { useDispatch } from "react-redux";
import { updateParameterValue, applyParameterValueChanges } from "../../../../../store/storyline/actions";
import { showSuccess } from "../../../../../store/notifications/actions";

import { FeedbackOutlined } from "@material-ui/icons";
import { Button, Dialog, DialogContent, TextField, DialogActions, Tooltip, Checkbox, FormControlLabel, IconButton, Avatar } from "@material-ui/core";
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import DialogTitleWithCloseButton from "../../../../../shared/components/DialogTitleWithCloseButton";
import { useForm, Controller } from "react-hook-form";
import { useAuth0 } from "../../../../../auth/AuthContext";

interface FeedbackModel {
    preventingBenefit: string;
    requiredChanges: string;
    willRealizeInTheFuture: boolean;
}

function ScavengerFeedbackDialog(props: any) {
    const { transactionNo } = props;
    const dispatch = useDispatch();
    const { user } = useAuth0();

    const validate = (values: FeedbackModel) => {
        if (!values.preventingBenefit && !values.requiredChanges && values.willRealizeInTheFuture === undefined) {
            return { form: "Please answer at least one question." };
        }

        return {};
    };
    const { register, handleSubmit, errors, reset, control } = useForm({
        resolver: async (values: any) => {
            const errors = validate(values);

            return {
                values,
                errors
            };
        }
    });

    React.useEffect(() => {
        if (!props.isOpen) return;

        reset();
    }, [props.isOpen]);

    const closeDialog = () => {
        props.setIsOpen(false);
    }

    const submitFeedback = (formModel: FeedbackModel) => {
        const request = {
            "user": user?.name || "Anonymous User",
            "transaction_no": transactionNo,
            "preventing_benefit": formModel.preventingBenefit,
            "required_changes": formModel.requiredChanges,
            "will_realize_in_future": formModel.willRealizeInTheFuture || false
        }

        dispatch(updateParameterValue("scavenger_feedback_payload", request));
        dispatch(applyParameterValueChanges(true));

        closeDialog();
        dispatch(showSuccess("Thank you for your feedback!"));
    };

    return (
        <Dialog open={props.isOpen} onClose={closeDialog} disableBackdropClick={true} aria-labelledby="form-dialog-title" maxWidth="md">
            <DialogTitleWithCloseButton onClose={closeDialog} id="form-dialog-title">
                Transaction Feedback
            </DialogTitleWithCloseButton>
            <form onSubmit={handleSubmit(submitFeedback)}>
                <DialogContent>
                    <br />
                    <TextField
                        name="preventingBenefit"
                        error={!!errors.preventingBenefit}
                        label="Does anything prevent you from realizing the benefit on this transaction?"
                        helperText={errors.preventingBenefit?.message}
                        multiline
                        rows={3}
                        inputRef={register}
                        variant="outlined"
                        fullWidth
                        autoFocus
                    />
                    <TextField
                        name="requiredChanges"
                        error={!!errors.requiredChanges}
                        label="What would you need to be changed in order to take advantage of this benefit?"
                        helperText={errors.requiredChanges?.message}
                        multiline
                        rows={3}
                        inputRef={register}
                        variant="outlined"
                        fullWidth
                    />
                    <Controller
                        name="willRealizeInTheFuture"
                        control={control}
                        render={props => (
                            <FormControlLabel
                                control={<Checkbox
                                    onChange={e => props.onChange(e.target.checked)}
                                    checked={props.value} />}
                                label="This is a saving that we will realize in future."
                            />
                        )}
                    />
                    {
                        errors.form &&
                        <div className="form-errors">
                            {errors.form}
                        </div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="contained" color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default function ScavengerFeedbackColumn(props: GridRenderCellParams) {
    const [feedbackDialogIsOpen, setFeedbackDialogIsOpen] = React.useState(false);
    const { row } = props;

    const buttonClicked = React.useCallback(
        (r) => {
            setFeedbackDialogIsOpen(true);
        }, []);

    return (
        <div className="scavenger-feedback-column">
            <Tooltip title="Submit Feedback about this transaction">
                <IconButton onClick={(e) => { buttonClicked(props); e.stopPropagation(); }} className="feedback-button">
                    <Avatar className={row?.has_feedback ? "bg-storychart-color-8" : "bg-storychart-color-4"}>
                        <FeedbackOutlined fontSize="inherit" />
                    </Avatar>
                </IconButton>
            </Tooltip>

            <ScavengerFeedbackDialog
                transactionNo={row?.transaction_no}
                isOpen={feedbackDialogIsOpen}
                setIsOpen={setFeedbackDialogIsOpen}
            />
        </div>
    );
}