export function convertStringToValue(valueString: string) {
    // Check if value is a number...
    const number = Number(valueString);
    if (!isNaN(number)) return number;

    // Check if value is a date...
    const date = new Date(valueString);
    if (date instanceof Date && !isNaN(date as any)) return date;

    // No matches - assume the value is a plain string...
    return valueString;
}

function convertAbbreviatedNumberToText(_, negative: string, currencyCode: string, number: number, uom: "k" | "m" | "b") {
    const uoms = {
        "k": " thousand",
        "m": " million",
        "b": " billion"
    };

    const currencies = {
        "R": " rand",
        "$": " dollar",
        "%": " percent"
    };

    return `${negative ? "minus " : ""}${number}${uoms[uom] || ""}${currencies[currencyCode] || ""}`;
}

export function sanitizeTextForNarration(text: string | string[]) {
    let result = (text instanceof Array) ? text.join(". ") : text; // Concatenate sentences together or use the single sentence...
    result = result.replace(/<[^>]+>/g, ''); // Remove all HTML markup...
    result = result.replace(/(-)*\s*(R|$)*\s*([0-9.0-9]+\s*)(k|m|b)?/g, convertAbbreviatedNumberToText); // Convert abbreviated numbers/percentages to their reader-friendly counterparts...
    return result;
}