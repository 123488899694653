import React from "react";
import "./App.scss";
import { Router, Switch, Route } from "react-router-dom";
import history from "../shared/history";
import { connect } from "react-redux";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { toggleMenu, loadApp } from "../store/app/actions";
import { incrementOutstandingRequestCount, decrementOutstandingRequestCount } from "../store/notifications/actions";
import { RootState } from "../store";
import { AppState } from "../store/app/types";
import Admin from "../admin";
import Viewer from "../viewer";
import Public from "../public";
import LoginCallback from "../auth/LoginCallback";
import fetchIntercept from 'fetch-intercept';
import { useAuth0 } from "../auth/AuthContext";
import { AjaxLoadingIndicator } from "./AjaxLoadingIndicator";
import { SnackbarProvider } from 'notistack';
import Notifier from "./Notifier";
import RequireAuthentication from "../auth/RequireAuthentication";
import Sidenav from "./Sidenav";
import UserPreferences from "../shared/components/UserPreferences";
import NotFound from "../shared/components/NotFound";

const theme = createTheme({
    palette: {
        primary: {
            main: '#2B60A2'
        },
        secondary: {
            main: '#689f38'
        }
    },
});

LicenseInfo.setLicenseKey(
    "c0999568c3def75091e9f9d50d778258T1JERVI6MjczNTksRVhQSVJZPTE2NTg0NzIyOTgwMDAsS0VZVkVSU0lPTj0x"
);

interface AppProps {
    app: AppState,
    toggleMenu: typeof toggleMenu,
    loadApp: typeof loadApp,
    incrementOutstandingRequestCount: typeof incrementOutstandingRequestCount,
    decrementOutstandingRequestCount: typeof decrementOutstandingRequestCount
}

function App(props: AppProps) {
    const { app, toggleMenu, loadApp, incrementOutstandingRequestCount, decrementOutstandingRequestCount } = props;
    const { isAuthenticated, getTokenSilently } = useAuth0();

    React.useEffect(() => {
        // Register an HTTP interceptor that adds the latest JWT token to the request headers and tracks outstanding AJAX requests...
        const unregister = fetchIntercept.register({
            request: async (url, config) => {
                if (url?.startsWith?.(window.location.origin) || url?.startsWith?.("/")) {
                    incrementOutstandingRequestCount();

                    if (isAuthenticated && config != null) {
                        const token = await getTokenSilently();
                        config.headers["Authorization"] = `Bearer ${token}`;
                    }
                }

                return [url, config];
            },
            requestError: function (error) {
                //if (!error.url.startsWith(window.location.origin)) return;

                decrementOutstandingRequestCount();

                // Called when an error occured during another 'request' interceptor call
                return Promise.reject(error);
            },

            response: function (response) {
                if (response?.url?.startsWith?.(window.location.origin) || response?.url?.startsWith?.("/")) {
                    decrementOutstandingRequestCount();
                }
                // Modify the reponse object
                return response;
            },

            responseError: function (error) {
                //if (!error.url.startsWith(window.location.origin)) return;

                decrementOutstandingRequestCount();

                // Handle an fetch error
                return Promise.reject(error);
            }
        });

        return unregister;
    }, [isAuthenticated, getTokenSilently]);

    React.useEffect(() => {
        if (!isAuthenticated) return;

        loadApp(); // Load the storylines and canvases for the current tenant once-off...
    }, [loadApp, isAuthenticated]);

    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}>
                <Notifier />
                {!app?.loading && <AjaxLoadingIndicator />}
                <Router history={history}>
                    <Sidenav toggleDrawer={toggleMenu} open={app.menuIsExpanded} />
                    <Switch>
                        <Route path="/not-found" component={NotFound} />
                        <Route path="/login-callback" component={LoginCallback} />
                        <Route path="/preferences" component={UserPreferences} />
                        <Route path="/public" component={Public} />
                        <Route path="/admin">
                            <RequireAuthentication>
                                <Admin />
                            </RequireAuthentication>
                        </Route>
                        <Route path="/">
                            <RequireAuthentication>
                                <Viewer />
                            </RequireAuthentication>
                        </Route>
                    </Switch>
                </Router>
            </SnackbarProvider>
        </ThemeProvider>
    );
}

export default connect(
    (state: RootState) => ({
        app: state.app
    }),
    { incrementOutstandingRequestCount, decrementOutstandingRequestCount, toggleMenu, loadApp: loadApp as any })(App);