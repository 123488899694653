import axios, { AxiosResponse } from 'axios';
import { PolygonFeatureCollection, GeoGpsDataModel } from './types';

export class GeoClient {
    public getGeoFeatures(code: string, coords: number[][]): Promise<AxiosResponse<PolygonFeatureCollection>> {
        const formatCoords = JSON.stringify(coords);
        const params = { code, bounding_coords: formatCoords };
        return axios.get('/api/getgeofeatures', { params });
    }

    public getGpsData(code: string, coords: number[][], max: number): Promise<AxiosResponse<GeoGpsDataModel[]>> {
        const formatCoords = JSON.stringify(coords);
        const params = { code, bounding_coords: formatCoords, max };
        return axios.get('/api/getgpsdata', { params });
    }
 
    public upsertGeo(code: string, model: PolygonFeatureCollection): Promise<AxiosResponse> {
        const params = { code };
        return axios.post('/api/upsertgeofeature', model, { params });
    }

    public deleteGeo(code: string, id: string): Promise<AxiosResponse> {
        const params = { code, ID: id };
        return axios.get('/api/deletegeobyid', { params });
    }
}