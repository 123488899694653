import React from "react";
import "./DownloadStoryline.scss";
import { CircularProgress, MenuItem, ListItemIcon, Typography } from "@material-ui/core";
import { CloudDownload } from '@material-ui/icons';
import { saveAs } from 'file-saver';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar, closeSnackbar, showError } from "../../../store/notifications/actions";
import { useSettings } from "../../../shared/providers/SettingsProvider";
import moment from "moment";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { StorylineState } from "../../../store/storyline/types";
import DownloadStorylineDialog, { DownloadStorylineOptions } from "./DownloadStorylineDialog";

interface DownloadStorylineProps {
    storyline: StorylineState;
    clickCallback?: () => void;
}

function DownloadStoryline(props: DownloadStorylineProps) {
    const { storyline } = props;
    const dispatch = useDispatch();
    const settings = useSettings();
    const [dialogIsOpen, setDialogIsOpen] = React.useState(false);

    const downloadClicked = () => {
        // Close pop-up menu...
        props?.clickCallback?.();
        // Open "Download Options" dialog...
        setDialogIsOpen(true);
    }

    // Populate storyline parameters based on the values selected in the "Export Options" dialog...
    const getDownloadSpecificParameters = (downloadOptions: DownloadStorylineOptions) => ({
        isExport: 1,
        hideTitleBar: downloadOptions.includeTitleBar ? 0 : 1,
        hideMinimap: downloadOptions.includeMinimap ? 0 : 1,
        hideCommentary: downloadOptions.includeCommentary ? 0 : 1
    })

    const exportClicked = (options: DownloadStorylineOptions) => {
        const url = settings.storylineDownloadApiUrl;
        if (!url) {
            dispatch(showError("Storyline download API is not configured."));
        }

        const parameters = { ...Object.fromEntries(storyline.parameterValues), ...getDownloadSpecificParameters(options) };

        const request = {
            body: JSON.stringify({
                localStorage,
                address: window.location.href,
                parameters,
                format: options.format
            }),
            method: "POST"
        } as RequestInit;

        const notificationsKey = `download-${new Date().getTime()}`;

        dispatch(enqueueSnackbar({
            message: "Storyline download in progress...",
            options: {
                key: notificationsKey,
                variant: "info",
                autoHideDuration: null,
                action: _ => <CircularProgress className="download-progress-indicator" />
            }
        }));

        fetch(url, request)
            .then(response => {
                if (response.ok) {
                    return response.blob().then(blob => {
                        saveAs(blob, `${props?.storyline?.name} - ${moment().format("yyyy-MM-DD")}.${options.format}`);
                    })
                }
                else {
                    throw Error(response.statusText);
                }
            })
            .catch(error => dispatch(showError("Download failed.  Please try again.")))
            .finally(() => dispatch(closeSnackbar(notificationsKey)));
    }

    return (
        <>
            <MenuItem onClick={downloadClicked}>
                <ListItemIcon>
                    <CloudDownload />
                </ListItemIcon>
                <Typography variant="inherit" noWrap>
                    Export Storyline
                </Typography>
            </MenuItem>

            <DownloadStorylineDialog isOpen={dialogIsOpen} setIsOpen={setDialogIsOpen} onExportClicked={exportClicked} />
        </>
    );
}

export default connect(
    (state: RootState) => ({
        storyline: state.storyline
    }),
    {})(DownloadStoryline)