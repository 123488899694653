import React from "react";
import "../viewer/components/Vdt/Vdt.scss";
import "./HeadlessVdt.scss";
import { ValueDriverTree } from "../viewer/components/Vdt/valueDriverTree";
import * as _ from "lodash";

export default function HeadlessVdt() {
    const vdtRef = React.useRef(null);

    const renderTree = (vdtObject: any) => {
        if (!vdtObject) {
            return;
        }

        const vdt = new ValueDriverTree(vdtRef.current, {
            width: vdtRef.current.clientWidth,
            height: vdtRef.current.clientHeight,
            ...vdtObject
        });
        vdt.root = vdtObject?.root;
        vdt.duration = 0; // Disable animation...
        vdt.draw(vdt.root as any);
        vdt.zoomToFit();
    };

    React.useEffect(() => {
        // Export the "renderHeadlessVdt" function, to allow us to call it externally...
        window["renderHeadlessVdt"] = renderTree;
    }, []);

    return (
        <div className="headless-vdt vdt-container">
            <div className="vdt-inner" ref={vdtRef}>
            </div>
        </div>
    );
}