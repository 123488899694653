import React from "react";
import {
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";
import HeadlessVdt from "./HeadlessVdt";

export default function Admin(props: unknown) {
    let match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}/headless-vdt`}>
                <HeadlessVdt />
            </Route>
        </Switch>
    );
}