import "./MapControls.scss";
import React from "react";
import { IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";

const mapStyles = [
    {
        "value": "basic",
        "title": "Basic"
    }, {
        "value": "streets",
        "title": "Streets"
    }, {
        "value": "outdoors",
        "title": "Outdoors"
    }, {
        "value": "light",
        "title": "Light"
    }, {
        "value": "dark",
        "title": "Dark"
    }, {
        "value": "satellite",
        "title": "Satellite"
    }, {
        "value": "satellite-streets",
        "title": "Satellite + Streets"
    }, {
        "value": "open-street-map",
        "title": "OpenStreetMap"
    }, {
        "value": "carto-positron",
        "title": "Carto - Positron"
    }, {
        "value": "carto-darkmatter",
        "title": "Carto - Dark Matter"
    }, {
        "value": "stamen-terrain",
        "title": "Stamen - Terrain"
    }, {
        "value": "stamen-toner",
        "title": "Stamen - Toner"
    }, {
        "value": "stamen-watercolor",
        "title": "Stamen - Watercolor"
    }
]

export default function MapControls(props) {
    const { mapStyle, setMapStyle } = props;
    const [mapStyleAnchorEl, setMapStyleAnchorEl] = React.useState(null);
    const handleMapStyleMenuClick = (event) => setMapStyleAnchorEl(event.currentTarget);
    const handleMapStyleMenuClose = () => setMapStyleAnchorEl(null);

    return (
        <div className="map-controls">
            <Tooltip arrow title="Select Map Style">
                <IconButton color="primary" onClick={handleMapStyleMenuClick}>
                    <Visibility />
                </IconButton>
            </Tooltip>

            <Menu
                id="map-style-menu"
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                keepMounted
                anchorEl={mapStyleAnchorEl}
                open={Boolean(mapStyleAnchorEl)}
                onClose={handleMapStyleMenuClose}
            >
                {
                    mapStyles.map(ms =>
                        <MenuItem className="map-style-option" onClick={() => setMapStyle(ms.value)} >
                            <span className={mapStyle === ms.value ? "selected" : ""}>{ms.title}</span>
                        </MenuItem>)
                }
            </Menu>

        </div>
    );
}

