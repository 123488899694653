import React from "react";
import { Auth0Context } from "../AuthContext";

export const AnonymousAuthProvider = ({
    children,
    ...rest
}) => {
    return (
        <Auth0Context.Provider
            value={{
                isAuthenticated: true,
                user: { name: "Anonymous User" },
                roles: ["ReportViewer"],
                loading: false,
                popupOpen: false,
                loginWithPopup: (...a) => { },
                handleRedirectCallback: (...a) => { },
                getIdTokenClaims: (...p) => { },
                loginWithRedirect: (...p) => { },
                getTokenSilently: (...p) => { },
                getTokenWithPopup: (...p) => { },
                logout: (...p) => { }
            }}
        >
            {children}
        </Auth0Context.Provider>
    );
};