import React from "react";
import "./InlineInfoTooltip.scss";
import { Tooltip } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';

interface Props {
    text: string
}

export default function InlineInfoTooltip(props: Props) {
    const { text } = props;

    return (
        <Tooltip title={text}>
            <HelpIcon className="inline-info-tooltip" />
        </Tooltip>
    );
}