import React from "react";
import { logger, SeverityLevel } from "../services/loggingService";

export default class AppInsightsErrorBoundary extends React.Component<any, any> {
    state = { hasError: false };

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        logger.trackException({
            error: error,
            exception: error,
            severityLevel: SeverityLevel.Error,
            properties: { ...info }
        });
    }

    render() {
        if (this.state.hasError) {
            const { onError } = this.props;
            return typeof onError === "function"
                ? onError()
                : React.createElement(onError);
        }

        return this.props.children;
    }
}

