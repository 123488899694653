import React from "react";
import "./KpiIndicator.scss";
import * as _ from "lodash";

interface IKpiDataPoint {
    title: string;
    value: string;
    class: string;
}

interface IKpiIndicator extends IKpiDataPoint {
    deltas: IKpiDataPoint[];
}

export default function KpiIndicator(props: { input: IKpiIndicator }) {
    const { input } = props;
    if (!input)
        return null;

    return (
        <div className="kpi-indicator">

            <div className="main-block">
                <div className="value">
                    {input.value}
                </div>
                <div className="label">
                    {input.title}
                </div>
            </div>

            {
                input?.deltas?.[0] &&
                <div className="main-delta">
                    <div className={`delta ${input.deltas[0].class}`}>
                        <div className="value">
                            {input.deltas[0].value}
                        </div>
                        <div className="label">
                            {input.deltas[0].title}
                        </div>
                    </div>
                </div>
            }

            <div className="deltas">
                {_.map(input?.deltas?.splice?.(1), d =>
                    <div className={`delta ${d.class}`}>
                        <div className="value">
                            {d.value}
                        </div>
                        <div className="label">
                            {d.title}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}