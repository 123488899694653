/* eslint-disable eqeqeq */
import React from 'react';
import "./Autocomplete.scss";
import * as _ from "lodash";
import { StorylineState } from "../../../store/storyline/types";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { updateParameterValue } from "../../../store/storyline/actions";
import MaterialAutocomplete from '@material-ui/lab/Autocomplete';
import { TextField, CircularProgress, InputAdornment } from '@material-ui/core';
import { BehaviorSubject } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { map, filter, switchMap, debounceTime, tap } from 'rxjs/operators';
import parse from 'autosuggest-highlight/parse';
import prefixMatch from "autosuggest-highlight/match";

function wildcardMatch(text, query) {
    if (!query) return [];

    const results = [];
    const trimmedQuery = query.trim().toLowerCase();
    const textLower = text.toLowerCase();
    const queryLength = trimmedQuery.length;
    let indexOf = textLower.indexOf(trimmedQuery);
    while (indexOf > -1) {
        results.push([indexOf, indexOf + queryLength]);
        indexOf = textLower.indexOf(query, indexOf + queryLength);
    }
    return results;
}

interface Option {
    value: any,
    label: string
}

interface Props {
    storyline: StorylineState,
    updateParameterValue:
    typeof updateParameterValue,
    name: string,
    options?: Option[],
    label?: string,
    multiple?: boolean,
    fetchUrl?: string,
    highlightMatchPattern?: "prefix" | "wildcard",
    placeholder?: string,
    onChange?: (event: React.ChangeEvent<HTMLDivElement>, value: Option | Option[]) => void
}

const subject$ = new BehaviorSubject("");

export function Autocomplete(props: Props) {
    const { storyline, multiple, options, name, onChange, fetchUrl, updateParameterValue, label, placeholder, highlightMatchPattern, ...other } = props;
    const matchHandler = highlightMatchPattern === "prefix" ? prefixMatch : wildcardMatch;
    
    const [availableOptions, setAvailableOptions] = React.useState<Option[]>(options || []);
    const [value, setValue] = React.useState(_.find(options, option => option?.value === storyline.parameterValues?.get(name)) || multiple ? [] as Option[] : { value: null, label: "" } as Option);
    const [inputValue, setInputValue] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    const handleChange = (event: any, newValue: Option | Option[]) => {
        if (newValue instanceof Array) {
            updateParameterValue(name, newValue.map(v => v.value));
        }
        else {
            updateParameterValue(name, newValue?.value);
        }

        if (onChange) {
            onChange(event, newValue);
        }
    };

    React.useEffect(() => {
        setAvailableOptions(options || []);
    }, [options]);

    React.useEffect(() => {
        if (storyline.parameterValues.has(name)) {
            const newValue = storyline.parameterValues.get(name);
            if (options) {
                if (multiple) {
                    setValue(_.filter(options, option => newValue?.find?.(v => v == option?.value) != null));
                }
                else if (newValue != (value as Option)?.value) {
                    setValue(_.find(options, option => option?.value == newValue));
                }
            } else {
                if (multiple) {
                    const newOptions = _.map(newValue, v => ({ value: v, label: `${v}` }))
                    setAvailableOptions(newOptions);
                    setValue(newOptions);
                }
                else {
                    if (newValue !== null && newValue !== undefined) {
                        const newOption = { value: newValue, label: `${newValue}` };
                        setAvailableOptions([newOption]);
                        setValue(newOption);
                    }
                    else {
                        setAvailableOptions([]);
                        setValue(null);
                    }                  
                }
            }
        }
    }, [storyline.parameterValues, options]);

    const getSuggestions = (subject: BehaviorSubject<string>) => {
        return subject.pipe(
            debounceTime(100), // wait until user stops typing
            filter((text: string) => text.length > 0), // send request only if search string is not empty
            map((text: string) => fetchUrl.replace("{searchTerm}", encodeURIComponent(text))), // form url for the API call
            tap(() => setLoading(true)), // show loading indicator
            switchMap((url: string) => ajax(url)), // call HTTP endpoint and cancel previous requests
            map(({ response }: AjaxResponse<any[]>) => response), // change response shape for autocomplete consumption
            tap(() => setLoading(false)) // hide loading indicator
        );
    };

    React.useEffect(() => {
        const subscription = getSuggestions(subject$).subscribe(
            suggestions => {
                setAvailableOptions(suggestions);
            },
            error => {
                // Ignore the error for now - perhaps show an icon in the text field going forward?
            }
        );

        return () => subscription.unsubscribe();
    }, [fetchUrl]);

    React.useEffect(() => {
        if (fetchUrl && inputValue !== "" && inputValue != (value as Option)?.value) {
            subject$.next(inputValue);
        }
    }, [inputValue]);

    return (
        <MaterialAutocomplete
            size="small"
            {...other}
            multiple={!!multiple}
            className="autocomplete"
            classes={{
                popper: "autocomplete MuiAutocomplete-popper"
            }}
            options={availableOptions}
            noOptionsText={(fetchUrl && !inputValue) ? "Enter text to search for options." : "No options available."}
            value={value}
            onChange={handleChange}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            blurOnSelect={!multiple}
            disableCloseOnSelect={multiple}
            openOnFocus
            getOptionSelected={(option: any, value: any) => option?.value == value?.value} 
            getOptionLabel={(option: any) => option?.label || ""}
            autoHighlight
            ChipProps={{color: "primary"}}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    placeholder={multiple && (value as Option[])?.length > 0 ? "" : placeholder}
                    InputProps={{
                        ...params.InputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                        endAdornment: (
                            <InputAdornment position="end">
                                {loading ? <CircularProgress color="primary" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </InputAdornment>
                        )
                    }}
                />
            )}
            renderOption={(option: Option, { inputValue }) => {
                const matches = matchHandler(option.label, inputValue);
                const parts = parse(option.label, matches);

                return (
                    <div>
                        {parts.map((part, index) => (
                            <span key={index} className={part.highlight ? "highlight-text" : ""}>
                                {part.text}
                            </span>
                        ))}
                    </div>
                );
            }}
        />
    );
}

export default connect(
    (state: RootState) => ({
        storyline: state.storyline
    }),
    { updateParameterValue: updateParameterValue as any })(Autocomplete);