import React from 'react';
import "./Slider.scss";
import { StorylineState } from "../../../store/storyline/types";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { updateParameterValue } from "../../../store/storyline/actions";
import MaterialSlider from '@material-ui/core/Slider';

interface Props {
    storyline: StorylineState;
    updateParameterValue: typeof updateParameterValue;
    name: string;
}

export function Slider(props: Props) {
    const { storyline, updateParameterValue, name, ...other } = props;
    const [value, setValue] = React.useState(storyline.parameterValues.get(name) || 0);

    const handleChange = (event: any, newValue: number | number[]) => {
        updateParameterValue(name, newValue);
        setValue(newValue);
    };

    React.useEffect(() => {
        
        if (storyline.parameterValues.has(name)) {
            const newValue = storyline.parameterValues.get(name);
            if (newValue !== value) {
                setValue(newValue);
            }
        }
        
    }, [storyline.parameterValues]);

    return (
        <MaterialSlider
            {...other}
            className="slider"
            value={value}
            onChange={(event, newValue) => setValue(newValue)}
            onChangeCommitted={handleChange}
            valueLabelDisplay="on"
            color="primary"/>
    );
}

export default connect(
    (state: RootState) => ({
        storyline: state.storyline
    }),
    { updateParameterValue: updateParameterValue as any })(Slider);