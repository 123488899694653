import React from "react";
import "./Sidenav.scss";
import { Drawer, Icon, SvgIcon, SvgIconProps, IconButton, Avatar, Input, InputAdornment, Collapse, CircularProgress } from "@material-ui/core";
import { TreeView, TreeItem } from "@material-ui/lab";
import { Search, Clear } from "@material-ui/icons";
import { MenuItemDisplayModel } from "../shared/api-client";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { AppState } from "../store/app/types";
import * as _ from "lodash";
import { RootState } from "../store";
import useLocalStorage from "../shared/hooks/useLocalStorage";
import { useAuth0 } from "../auth/AuthContext";
import UserProfileBadge from "../shared/components/UserProfileBadge";
import AdminMenuButton from "../shared/components/AdminMenuButton";
import FeedbackWidget from "../shared/components/FeedbackWidget";
import { BehaviorSubject } from "rxjs";
import { tap, debounceTime } from 'rxjs/operators';


function MinusSquare(props: SvgIconProps) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

function PlusSquare(props: SvgIconProps) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

function filterMenuItems(menuItem: MenuItemDisplayModel, searchTerm: string) {
    // Search term is blank or this node matches the search criteria...
    if (!searchTerm || menuItem.title.toLowerCase().includes(searchTerm.toLowerCase())) {
        return menuItem;
    }

    const survivors = _.chain(menuItem.children).map(a => filterMenuItems(a, searchTerm)).filter(a => !!a).value();

    if (survivors?.length > 0) {
        return {
            ...menuItem,
            children: survivors
        };
    } else {
        return null;
    }
}

function getFullHierarchy(item: MenuItemDisplayModel) {
    return [item, _.flatMap(item.children, getFullHierarchy)];
}

function highlightSearchTerm(text: string, searchTerm: string) {
    const startIndex = text.toLowerCase().indexOf(searchTerm.toLowerCase());

    if (startIndex === -1) return <span>{text}</span>;

    const endIndex = startIndex + searchTerm.length;
    return <span>
        {text.slice(0, startIndex)}
        <mark>{text.slice(startIndex, endIndex)}</mark>
        {text.slice(endIndex, text.length)}
    </span>;
}

function getPathToItem(searchTarget: MenuItemDisplayModel, currentItem: MenuItemDisplayModel, currentPath: string[] = []): string[] {
    if (currentItem === searchTarget) {
        return currentPath;
    }
    if (!currentItem.children) {
        return null;
    }

    return _.chain(currentItem.children).map(c => getPathToItem(searchTarget, c, [...currentPath, currentItem.id])).find(a => !!a).value();
}

// It gets complicated when you try to trim the branches midway, so instead we just cap the number of leaf nodes in the tree when rendering the search results.
function takeNLeafNodesFromTree(tree: MenuItemDisplayModel[], n: number) {
    let remainder = n;

    const getPrunedMenuItem = (item: MenuItemDisplayModel) => {
        if (!item.children.length) {
            if (remainder > 0) {
                remainder--;
                return item;
            }
            else {
                return null;
            }
        }
        else {
            let trimmedChildren = item.children.map(getPrunedMenuItem).filter(a => !!a);
            if (!trimmedChildren.length) {
                return null;
            }
            else {
                return { ...item, children: trimmedChildren };
            }
        }
    }

    return tree.map(getPrunedMenuItem).filter(a => !!a);
}

const searchTermSubject$ = new BehaviorSubject<string>("");

function Sidenav(props: { toggleDrawer: () => void, app: AppState, open: boolean }) {
    const menuItems = props.app.menuItems;

    const [expandedNodes, setExpandedNodes] = useLocalStorage("side-nav-expanded-nodes", []);
    const [showSearch, setShowSearch] = React.useState(false);
    const searchField = React.useRef(null);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [searchTermText, setSearchTermText] = React.useState("");
    const [selectedNavItem, setSelectedNavItem] = React.useState(null);
    const [filteredMenuItems, setFilteredMenuItems] = React.useState([]);
    const [expandedSearchNodes, setExpandedSearchNodes] = React.useState([]);
    const { user } = useAuth0();

    const toggleShowSearch = () => {
        if (showSearch) {
            // Make sure the last selected nav item (the active page) is expanded when we exit search mode...
            if (selectedNavItem) {
                const parentsToExpand = _.chain(props.app.menuItems).map(a => getPathToItem(selectedNavItem, a)).find(a => !!a).value();
                const expandedNodesIncludingActiveLink = _.union(expandedNodes, parentsToExpand);
                setExpandedNodes(expandedNodesIncludingActiveLink);
            }

            setSearchTerm("");
            setSearchTermText("");
            setShowSearch(false);
        } else {
            setShowSearch(true);
            setTimeout(() => searchField?.current?.focus?.(), 500);
        }
    }

    const onNodeToggle = (event: any, nodeIds: string[]) => {
        if (!searchTerm) {
            setExpandedNodes(nodeIds);
        }
    }

    const renderMenuItemSubTree = (menuItem: MenuItemDisplayModel, depth = 1) => {
        if (menuItem.children?.length) {
            return (
                <TreeItem key={menuItem.id} nodeId={menuItem.id} className={`menu-group-level-${depth}`} label={
                    <div className="menu-item-label">
                        {menuItem.icon && <Icon>{menuItem.icon}</Icon>}
                        {highlightSearchTerm(menuItem.title, searchTerm)}
                    </div>
                }>
                    {menuItem.children && menuItem.children.map(item => renderMenuItemSubTree(item, depth + 1))}
                </TreeItem>
            );
        }
        else {
            return (
                <NavLink key={menuItem.id} to={menuItem.url || "/not-found"}>
                    <TreeItem nodeId={menuItem.id} className={`menu-group-level-${depth}`} onClick={() => setSelectedNavItem(menuItem)} label={
                        <div className="menu-item-label">
                            {menuItem.icon && <Icon>{menuItem.icon}</Icon>}
                            {highlightSearchTerm(menuItem.title, searchTerm)}
                        </div>
                    }>
                    </TreeItem>
                </NavLink>
            );
        }
    }

    React.useEffect(() => {
        const searchTermSubscription = searchTermSubject$
            .pipe(
                tap(setSearchTermText), // Immediately update the text in the input control...
                debounceTime(500)       // And debounce the update of the search term...
        ).subscribe(setSearchTerm);

        return () => searchTermSubscription.unsubscribe();
    }, []);

    React.useEffect(() => {
        if (searchTerm) {
            let filteredResults = _.chain(props.app.menuItems).map(a => filterMenuItems(a, searchTerm)).filter(a => !!a).value();
            filteredResults = takeNLeafNodesFromTree(filteredResults, 200); // Cap the search results at 200 leaf nodes, for performance reasons...
            setFilteredMenuItems(filteredResults.map(item => renderMenuItemSubTree(item)));
            setExpandedSearchNodes(_.chain(filteredResults).flatMapDeep(getFullHierarchy).filter(mi => mi?.children?.length > 0).map(mi => mi.id).value());
        }
        else {
            setFilteredMenuItems(menuItems.map(item => renderMenuItemSubTree(item)));
            setExpandedSearchNodes([]);
        }
    }, [menuItems, searchTerm]);

    return (
        <Drawer
            className="sidenav"
            anchor="left"
            open={props.open}
            onClose={props.toggleDrawer}
        >
            <div className="drawer-header">
                <div className="top-row">
                    <div className="logo-container">
                        <Link className="logo" to="/">
                            <img src="/logo-white-text.png" alt="The StoryChart Logo" />
                        </Link>
                    </div>
                    <div className="flex-grow-0">
                        <IconButton id="btn-search-menu-items" className="search-menu-items" onClick={toggleShowSearch}>
                            <Avatar className={`bg-${showSearch ? "secondary" : "storychart-color-4"}`}>
                                <Search />
                            </Avatar>
                        </IconButton>
                    </div>
                </div>

                <div className="search-row">
                    <Collapse in={showSearch} className="fill">
                        <Input
                            className="search-menu-items-input"
                            inputRef={searchField}
                            fullWidth
                            startAdornment={
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        className="clear-search"
                                        aria-label="Clear Search"
                                        onClick={toggleShowSearch}
                                    >
                                        <Clear />
                                    </IconButton>
                                </InputAdornment>
                            }
                            value={searchTermText}
                            onChange={(event) => searchTermSubject$.next(event.target.value)}
                        />
                    </Collapse>
                </div>
            </div>
            <div className="menu-items">
                {
                    props.app.loading ?
                        <div><CircularProgress className="loading-spinner-inline" /></div> :
                        filteredMenuItems?.length > 0 ?
                            <TreeView
                                // By updating the key whenever the search term changes, the TreeView doesn't need to worry about collapsing/expanding nodes (with the subsequent layout thrashing)...
                                key={`menu-tree-${searchTerm}`}
                                onNodeToggle={onNodeToggle}
                                expanded={!searchTerm ? expandedNodes : expandedSearchNodes}
                                disableSelection={true}
                                defaultCollapseIcon={!searchTerm ? <MinusSquare /> : null}
                                defaultExpandIcon={!searchTerm ? <PlusSquare /> : null}
                            >
                                {filteredMenuItems}
                            </TreeView> :
                            <div className="no-items">No items found.</div>
                }
            </div>
            <div className="drawer-footer">
                {user &&
                    <>
                        <UserProfileBadge />
                        <div className="user-profile">
                            <div>Logged in as:</div>
                            <div>{user?.name}</div>
                        </div>
                        <AdminMenuButton />
                    </>
                }
                <FeedbackWidget />
            </div>

        </Drawer>
    );
}

export default connect(
    (state: RootState) => ({
        app: state.app
    }),
    {})(Sidenav);