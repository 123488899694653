import React from 'react';
import "./FocusContainer.scss";
import { IconButton, Tooltip } from "@material-ui/core";
import { SettingsOverscan, ZoomOut } from "@material-ui/icons";

interface Props {
    className?: string;
    children?: any;
}

export default function FocusContainer(props: Props) {
    const { className, children } = props;
    const [[isExpanded, wasExpanded], setExpansionState] = React.useState([false, false]);

    return <div className={`focus-container ${isExpanded ? "focused" : wasExpanded ? "restored" : ""} ${className || ""}`}>
        <div className="buttons">
            {
                !isExpanded &&
                <Tooltip arrow title="Focus On This Section">
                    <IconButton color="primary" onClick={() => setExpansionState([true, false])}>
                        <SettingsOverscan />
                    </IconButton>
                </Tooltip>
            }

            {
                isExpanded &&
                <Tooltip arrow title="Return To Report">
                    <IconButton color="primary" onClick={() => setExpansionState([false, true])}>
                        <ZoomOut />
                    </IconButton>
                </Tooltip>
            }
        </div>
        {children}
    </div>;
}