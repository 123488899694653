import React from 'react'
import { Dialog as Modal, DialogContent } from '@material-ui/core'
import { useDialogStore } from './store';
import DialogTitleWithCloseButton from '../../../../../shared/components/DialogTitleWithCloseButton';

export const Dialog: React.FunctionComponent = () => {
    const dialog = useDialogStore(state => state.dialog);
    const hideDialog = useDialogStore(state => state.hide);

    function handleClose() {
        hideDialog();
    }

    return (
        <Modal open={dialog.isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitleWithCloseButton onClose={handleClose} id="form-dialog-title">
                {dialog.title}
            </DialogTitleWithCloseButton>
            {dialog.component}
        </Modal>
    )
}