import React, { ChangeEvent } from "react";
import "./MetadataEditor.scss";
import { Button, IconButton, TextField } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';

interface Entry {
    field: string;
    value: string;
}

interface Props {
    entries: Entry[];
    updateEntries: (newEntries: Entry[]) => void;
}

export default function MetadataEditor(props: Props) {
    const { entries, updateEntries } = props;

    const createRow = () => {
        const newEntry = { field: "", value: "" };
        updateEntries([...(entries || []), newEntry]);
    }

    const deleteRow = (index: number) => () => {
        const newEntries = entries.filter((_, i) => i !== index);
        updateEntries(newEntries);
    }

    const getFieldValue = (index: number, fieldName: string) => {
        return entries?.[index]?.[fieldName];
    }

    const setFieldValue = (index: number, fieldName: string) => (event: ChangeEvent<HTMLInputElement>) => {
        const rows = entries.slice();
        rows[index][fieldName] = event.target.value;
        updateEntries(rows);
    }

    return (
        <div className="metadata-editor">
            {
                entries?.length > 0 ?
                    <table className="table compact">
                        <thead>
                            <tr>
                                <th>Field</th>
                                <th>Value</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                entries.map((_, index) =>
                                    <tr>
                                        {
                                            ["field", "value"].map(fieldName =>
                                                <td>
                                                    <TextField
                                                        size="small"
                                                        variant="outlined"
                                                        fullWidth
                                                        multiline={fieldName === "value"}
                                                        value={getFieldValue(index, fieldName)}
                                                        onChange={setFieldValue(index, fieldName)}
                                                    />
                                                </td>
                                            )
                                        }
                                        <td>
                                            <IconButton onClick={deleteRow(index)}>
                                                <DeleteIcon className="icon-delete" />
                                            </IconButton>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table> :
                    <div className="no-metadata">No User Metadata captured.</div>
            }
            <div className="footer">
                <Button variant="contained" color="secondary" onClick={createRow} size="small">
                    Add Metadata Entry
                </Button>
            </div>
        </div>
    );
}