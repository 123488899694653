import React from 'react'
import { CircularProgress } from '@material-ui/core';
import { useLoaderStore } from './store';

export const Loader: React.FunctionComponent = () => {
    const isLoading = useLoaderStore(state => state.isLoading);

    if (!isLoading) return null;

    return (
        <div className="overlay">
            <div className="d-flex align-items-center justify-content-center" style={{ height: "100%" }}>
                <CircularProgress role="status" />
            </div>
        </div>
    )
}