import React from "react";
import RunOnceTour from "../../../tours/RunOnceTour";
import Joyride, { Props as JoyrideProps } from "react-joyride";

const defaultOptions = {
    continuous: true,
    showSkipButton: true,
    spotlightPadding: 0,
    disableScrolling: true,
    locale: {
        back: 'Back',
        close: 'Close',
        last: 'Close',
        next: 'Next',
        skip: 'Skip Tour'
    },
    floaterProps: {
        disableAnimation: true
    },
    showProgress: true,
    disableBeacon: true
}

const styles = {
    options: {
        zIndex: 1400
    }
}

export interface CanvasTourProps extends JoyrideProps {
    autoRun?: boolean;
    runOnce?: boolean;
}

export default function CanvasTour(props: any) {
    const { autoRun, runOnce } = props;
    const [run, setRun] = React.useState(false);

    // Add a delay to the tour's start in order to make sure the slide transition/render has completed...
    React.useEffect(() => {
        autoRun && setTimeout(() => setRun(true), 1500);
    }, [autoRun]);

    const TourComponent = runOnce ? RunOnceTour : Joyride;

    const steps = props?.steps?.map(s => ({
        ...s,
        content: <div dangerouslySetInnerHTML={{__html: s?.content || "" }} />
    }));

    return (
        <TourComponent
            {...defaultOptions}
            styles={styles}
            {...props}
            run={autoRun ? run : props.run}
            steps={steps}
        />
    );
}