import * as api from "../../shared/api-client";

export interface Slide {
    id: string;
    canvasId: string;
    name: string;
    template: Template;
    datasourceIds: string[];
    frames: any[]
}

export interface Frame {
    name: string;
    template: Template;
    frame: any;
}

export interface Template {
    id: string;
    name: string;
    contents: string;
    customCss: string;
}

export interface StorylineState {
    id: string;
    name: string;
    slides: Slide[][];
    datasources: Map<string, api.DatasourceDisplayModel>;
    datasourceValues: Map<string, any[]>;
    parameterValues: Map<string, any>;

    xIndex: number;
    yIndex: number;
    previousFrameIndex?: number;
    frameIndex: number;

    currentFrame?: Frame;
    loading: boolean;
}

export interface NavigationTarget {
    xIndex: number,
    yIndex: number,
    frameIndex: number,
    previousFrameIndex?: number
}

export const LOAD_STORYLINE = "LOAD_STORYLINE";
export const SHOW_STORYLINE = "SHOW_STORYLINE";
export const SHOW_CANVAS_AS_STORYLINE = "SHOW_CANVAS_AS_STORYLINE";
export const PARAMETER_VALUE_UPDATED = "PARAMETER_VALUE_UPDATED";
export const DATASOURCE_UPDATED = "DATASOURCE_UPDATED";
export const GO_TO_X_Y_Z = "GO_TO_X_Y_Z";
export const GO_TO_ID = "GO_TO_ID";
export const UPDATE_CURRENT_SLIDE_TEMPLATE = "UPDATE_CURRENT_SLIDE_TEMPLATE";
export const UPDATE_CURRENT_FRAME_DATA = "UPDATE_CURRENT_FRAME_DATA";

interface LoadStorylineAction {
    type: typeof LOAD_STORYLINE,
    id: string
}

interface ShowStorylineAction {
    id: string,
    type: typeof SHOW_STORYLINE,
    storyline: api.StorylineDisplayModel,
    canvasData: Map<string, any[]>,
    parameterValues: Map<string, any>
}

interface ShowCanvasAsStorylineAction {
    id: string,
    type: typeof SHOW_CANVAS_AS_STORYLINE,
    canvas: api.CanvasDisplayModel,
    canvasData: Map<string, any[]>,
    parameterValues: Map<string, any>
}

interface ParameterValueUpdatedAction {
    type: typeof PARAMETER_VALUE_UPDATED,
    parameterName: string,
    newValue: any
}

interface DatasourceUpdatedAction {
    type: typeof DATASOURCE_UPDATED,
    datasourceId: string,
    data: any[]
}

interface GoToXYZAction {
    type: typeof GO_TO_X_Y_Z,
    xIndex: number,
    yIndex: number,
    frameIndex: number,
    previousFrameIndex?: number
}

interface GoToIDAction {
    type: typeof GO_TO_ID,
    id: string
}

interface UpdateCurrentSlideTemplate {
    type: typeof UPDATE_CURRENT_SLIDE_TEMPLATE,
    newTemplate: string,
    newCustomCss: string
}

interface UpdateCurrentFrameData {
    type: typeof UPDATE_CURRENT_FRAME_DATA,
    newData: any
}

export type StorylineActionTypes = LoadStorylineAction | ShowStorylineAction | ShowCanvasAsStorylineAction | ParameterValueUpdatedAction | DatasourceUpdatedAction | GoToXYZAction | GoToIDAction | UpdateCurrentSlideTemplate | UpdateCurrentFrameData;