import React from "react";
import { Card, CardHeader, CardContent, Button } from "@material-ui/core";
import { useAuth0 } from "./AuthContext";

export default function LoginCallback() {
    const { logout } = useAuth0();

    const urlParams = new URLSearchParams(window.location.search);
    const error = urlParams.get("error");
    const error_description = urlParams.get("error_description");

    return error ?
        <Card className="screen-centered" style={{maxWidth: "600px"}}>
            <CardHeader title="Login failed" />
            <CardContent className="text-align-center">
                {error_description}
                <br />
                <br />
                <br />
                <Button variant="contained" color="primary" onClick={() => logout({ returnTo: window.location.origin })}>Log out</Button>
            </CardContent>
        </Card>
        :
        <Card className="screen-centered">
            <CardHeader title="Login succeeded" />
            <CardContent className="text-align-center" style={{padding: "40px"}}>
                Redirecting - please wait...
            </CardContent>
        </Card>
}