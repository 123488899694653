import React from "react";
import { Avatar, IconButton, MenuItem, ListItemIcon, Menu } from "@material-ui/core";
import { ExitToApp, Settings } from "@material-ui/icons";
import { useAuth0 } from "../../auth/AuthContext";
import { Link } from "react-router-dom";

export default function UserProfileBadge(props) {
    const { user, logout } = useAuth0();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton id="btn-user-profile" aria-controls="user-menu" aria-haspopup="true" onClick={handleClick}>
                <Avatar alt={user?.name} src={user?.picture} />
            </IconButton>
            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <Link to="/preferences">
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon><Settings /></ListItemIcon>
                        Preferences
                    </MenuItem>
                </Link>
                <MenuItem onClick={() => logout({ returnTo: window.location.origin })}>
                    <ListItemIcon><ExitToApp /></ListItemIcon>
                    Log Out
                </MenuItem>
            </Menu>
        </>
    );
}