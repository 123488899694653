import "./Home.scss";
import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../store";
import { toggleMenu } from "../../store/app/actions";
import logo from "../../assets/logo.png";
import NavBar from "../../shared/components/NavBar";

export function Home(props: any) {
    const { toggleMenu } = props;

    // Expand the menu when the home page is viewed...
    React.useEffect(() => {
        toggleMenu();
    }, [toggleMenu]);

    return (
        <>
            <NavBar title="Welcome to StoryChart" />

            <div className="home-content">
                <img id="home-logo" src={logo} alt="The StoryChart logo" />
                <br />
                <br />
                <h2 id="home-header-1">Welcome to StoryChart!</h2>
                <br />
                <h3 id="home-header-2">Select a Storyline/Canvas from the left menu to continue.</h3>
            </div>
        </>
    );
}

export default connect(
    (state: RootState) => ({
        storyline: state.storyline
    }),
    { toggleMenu })(Home);