import React from 'react';
import "./Mosaic.scss";
import ReactTooltip from 'react-tooltip';
import * as _ from "lodash";

interface Heading {
    foregroundColor?: string,
    backgroundColor?: string,
    text: string
}

interface MosaicCell {
    class: string,
    fields: string[],
    progressBar?: { fillPercentage: number, text: string, fillColor: string, backgroundColor: string, foregroundColor: string },
    backgroundColor?: string,
    foregroundColor?: string,
    tooltip: string
}

interface MosaicProps {
    xHeadings: (string | Heading)[],
    yHeadings: (string | Heading)[],
    cells: MosaicCell[][],
    fontSize?: string
}

export default function Mosaic(props: { input: MosaicProps, [key: string]: any }) {
    const { input } = props;

    const xLength = input?.cells?.length;
    const yLength = input?.cells?.[0]?.length;

    if (!input) return "Mosaic requires a non-null 'input' property.";

    return (
        <div className="mosaic-container">
            <div className={`mosaic ${props.className} ${input.fontSize}`} style={{ gridTemplateColumns: `200px repeat(${xLength}, 1fr)`, gridTemplateRows: `100px repeat(${yLength}, 1fr)`, ...props.style }}>
                <div className="heading" style={{ gridColumnStart: 1, gridRowStart: 1 }}>&nbsp;</div>
                {_.map(input.xHeadings, (val, xi) => <div className="heading top-row" style={{ gridRowStart: 1, gridColumnStart: xi + 2, backgroundColor: val?.["backgroundColor"], color: val?.["foregroundColor"] }} dangerouslySetInnerHTML={{ __html: val?.["text"] || val }} />)}
                {_.map(input.yHeadings, (val, yi) => <div className="heading left-row" style={{ gridRowStart: yi + 2, gridColumnStart: 1, backgroundColor: val?.["backgroundColor"], color: val?.["foregroundColor"] }} dangerouslySetInnerHTML={{ __html: val?.["text"] || val }} />)}
                {
                    _.flatMap(input.cells, (column, xi) =>
                        _.map(column, (cell, yi) =>
                            <>
                                <div className={`cell ${cell.class} ${xi > 0 && "bordered-left"} ${yi > 0 && "bordered-top"}`} style={{ gridRowStart: yi + 2, gridColumnStart: xi + 2, backgroundColor: cell.backgroundColor, color: cell.foregroundColor }} data-for="mosaic-tooltip" data-tip={cell.tooltip}>

                                    <div className="fields">
                                        {
                                            _.map(cell.fields, field =>
                                                <div className="field" dangerouslySetInnerHTML={{ __html: field }}>
                                                </div>
                                            )
                                        }
                                    </div>

                                    {
                                        cell.progressBar &&
                                        <div className="progressbar" style={{ backgroundColor: cell.progressBar.backgroundColor }}>
                                            <div className="fill" style={{ width: `${cell.progressBar.fillPercentage * 100}%`, backgroundColor: cell.progressBar.fillColor }} />
                                            <div className="text" style={{ color: cell.progressBar.foregroundColor }} dangerouslySetInnerHTML={{ __html: cell.progressBar.text }}></div>
                                        </div>
                                    }
                                </div>
                            </>
                        )
                    )
                }
            </div>
            <ReactTooltip id="mosaic-tooltip" html={true} />
        </div>);
}