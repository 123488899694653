import { AppState, AppActionTypes, LOAD_APP, SHOW_APP, TOGGLE_MENU } from "./types";

const initialState: AppState = {
    loading: true,
    menuItems: [],
    userMetadata: {},
    menuIsExpanded: false
}

export function appReducer(
    state = initialState,
    action: AppActionTypes
): AppState {
    switch (action.type) {
        case LOAD_APP:
            return {
                ...state,
                loading: true
            }

        case SHOW_APP:
            return {
                ...state,
                loading: false,
                menuItems: action.menuItems,
                userMetadata: action.userMetadata
            }

        case TOGGLE_MENU:
            return {
                ...state,
                menuIsExpanded: !state.menuIsExpanded
            }
        
        default:
            return state;
    }
}