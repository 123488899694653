import React from "react";
import useQueryParams from "../hooks/useQueryParams";
import NavBar from "./NavBar";
import { logger } from "../services/loggingService";

export default function Error(props) {
    const query = useQueryParams();
    const originalPath = query.get("original-path");

    React.useEffect(() => {
        logger.trackEvent({
            name: "404 - Not Found",
            properties: {
                path: originalPath
            }
        });
    }, []);

    return (
        <>
            <NavBar title="Not Found" />

            <div className="home-content">
                <h2 id="home-header-2">We're sorry, but the requested resource does not exist.</h2>
                <br />
                <br />
                {
                    originalPath ?
                        <>
                            <pre style={{fontSize: 16}}>{originalPath}</pre>
                            <br />
                            <br />
                        </> :
                        <></>
                }
                <h3 id="home-header-1">Please try a different link from the navigation menu.</h3>
            </div>
        </>
    );
}