import * as api from "../../shared/api-client"
import {
    LOAD_APP,
    SHOW_APP,
    TOGGLE_MENU
} from "./types";
import { initializeDatasourceFetchPipeline } from "../storyline/actions";


export function loadApp() {
    return async (dispatch, getState) => {
        dispatch({
            type: LOAD_APP
        });

        initializeDatasourceFetchPipeline()(dispatch, getState);

        const menuItems = await new api.MenuItemsClient().getAll();
        const userMetadata = await new api.SettingsClient().getCurrentUserMetadata();

        dispatch({
            type: SHOW_APP,
            menuItems,
            userMetadata
        });
    }
}

export function toggleMenu() {
    return {
        type: TOGGLE_MENU
    }
}