import React, { useContext } from "react";
import { ClientAppSettings } from "../api-client";

export const SettingsContext: React.Context<ClientAppSettings> = React.createContext(null);
export const useSettings: () => ClientAppSettings = () => useContext(SettingsContext);
export const SettingsProvider = ({
    settings,
    children
}: { settings: ClientAppSettings, children: any }) => (
        <SettingsContext.Provider value={settings}>
            {children}
        </SettingsContext.Provider >
    );