import { useLocation } from "react-router-dom";
import { convertStringToValue } from "../utilities";
import * as _ from "lodash";

// A custom hook that builds on useLocation to parse
// the query string for you.
export default function useQueryParams() {
    const urlSearchParams = new URLSearchParams(useLocation().search);
    return _.reduce(
        Array.from(urlSearchParams.entries()),
        (map, [name, value]) => map.set(name, convertStringToValue(value)),
        new Map<string, any>());
}