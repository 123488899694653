import React from "react";
import "./StorylineMenu.scss";
import { IconButton, Menu } from "@material-ui/core";
import { MoreVert as MenuIcon } from '@material-ui/icons';
import DownloadStoryline from "./DownloadStoryline";


export default function StorylineMenu(props: any) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return <>
        <IconButton
            edge="end"
            color="inherit"
            aria-controls="storyline-menu"
            aria-haspopup="true"
            onClick={handleClick}>
            <MenuIcon />
        </IconButton>
        <Menu
            id="storyline-menu"
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left"
            }}
            keepMounted
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <DownloadStoryline clickCallback={handleClose} />
        </Menu>
    </>;
}