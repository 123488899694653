import React from "react";
import ReactDOM from "react-dom";
import "./DataGrid.scss";
import ReactDataGrid from "fixed-react-data-grid-custom";
import { Menu, MenuItem, Input, Dialog, DialogContent, DialogActions, Typography, Slider, Button } from "@material-ui/core";
import { KeyboardDatePicker } from '@material-ui/pickers';
import * as _ from "lodash";
import DialogTitleWithCloseButton from "../../../shared/components/DialogTitleWithCloseButton";
import { useForm, Controller } from "react-hook-form";
import { object, number } from "yup";
import { yupResolver } from "@hookform/resolvers";
import { connect } from "react-redux";
import { updateParameterValue } from "../../../store/storyline/actions";

interface Column {
    key: string;
    name: string;
    type?: "text" | "number" | "date";
    sortable: boolean;
}

interface DataGridInput {
    columns: Column[];
    rowKey: string;
    rows: any[];
}

interface CellCoordinate {
    rowIdx: number;
    colIdx: number
}

interface SelectionRange {
    topLeft?: CellCoordinate;
    botRight?: CellCoordinate;
}

interface MouseCoordinates {
    x?: number;
    y?: number;
}

interface AdjustValuesDialogProps {
    isOpen: boolean;
    setIsOpen: (val: boolean) => void;
    adjustSelectedCellValues: (adjustmentPercentage: number) => void;
}

interface DataGridProps {
    input: DataGridInput;
    name: string;
    updateParameterValue: typeof updateParameterValue;
}

function AdjustValuesDialog(props: AdjustValuesDialogProps) {
    const schema = object().shape({
        adjustmentPercentage: number()
    });
    const { handleSubmit, control } = useForm({ resolver: yupResolver(schema), defaultValues: { adjustmentPercentage: 0 } });

    const closeDialog = () => {
        props.setIsOpen(false);
    }

    const save = (formModel: { adjustmentPercentage: number }) => {
        props.adjustSelectedCellValues(formModel.adjustmentPercentage);
        closeDialog();
    };

    return (
        <Dialog open={props.isOpen} onClose={closeDialog} disableBackdropClick={true} aria-labelledby="form-dialog-title">
            <DialogTitleWithCloseButton onClose={closeDialog} id="form-dialog-title">
                Adjust Selected Values
            </DialogTitleWithCloseButton>
            <form onSubmit={handleSubmit(save)}>
                <DialogContent style={{ padding: "16px 32px"}}>
                    <Typography gutterBottom>
                        Adjustment %
                </Typography>
                    <Controller
                        name="adjustmentPercentage"
                        render={props =>
                            <Slider
                                value={props.value}
                                onChange={(_, value) => {
                                    props.onChange(value);
                                }}
                                step={1}
                                min={-100}
                                max={100}
                                valueLabelDisplay="on"
                                valueLabelFormat={value => `${value > 0 ? "+" : ""}${value}%`}
                            />
                        }
                        control={control}
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="contained" color="primary">
                        Apply
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

class NumericEditor extends React.Component<any, any, any> {
    constructor(props) {
        super(props);
        this.state = { value: props.value };
    }

    getValue() {
        return { [this.props.column.key]: this.state.value };
    }

    getInputNode() {
        return (ReactDOM.findDOMNode(this) as Element).getElementsByTagName("input")[0];
    }

    onChange = e => {
        this.setState({ value: e.target.value });
    };

    render() {
        return (
            <Input
                type="number"
                fullWidth
                defaultValue={this.props.value}
                onChange={this.onChange}
            />
        );
    }
}

class DateEditor extends React.Component<any, any, any> {
    constructor(props) {
        super(props);
        this.state = { value: props.value };
    }

    getValue() {
        if (this.state.value?.format) {
            return { [this.props.column.key]: this.state.value?.format?.("yyyy/MM/DD") };
        }

        return null;
    }

    getInputNode() {
        return (ReactDOM.findDOMNode(this) as Element).getElementsByTagName("input")[0];
    }

    onChange = date => {
        this.setState({ value: date });
    };

    render() {
        return (
            <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="yyyy/MM/DD"
                value={this.state.value}
                onChange={this.onChange}
            />
        );
    }
}

const customEditors = {
    "number": NumericEditor,
    "date": DateEditor
}

function mapColumn(column: Column) {
    return {
        ...column,
        editor: customEditors?.[column.type]
    }
}

const defaultParsePaste = str => (
    str.split(/\r\n|\n|\r/)
        .map(row => row.split('\t'))
);

function DataGrid(props: DataGridProps) {
    const { name, updateParameterValue } = props;
    const input = props.input || { "rows": [], "columns": [], "rowKey": "" };
    const inputRows = input.rows;
    const inputColumns = input.columns;
    const [selectionRange, setSelectionRange] = React.useState<SelectionRange>({});
    const [rows, setRows] = React.useState(inputRows);
    const gridRef = React.useRef(null);
    const [contextMenuCoordinates, setContextMenuCoordinates] = React.useState<MouseCoordinates>({ x: null, y: null });
    const [columns, setColumns] = React.useState(_.map(inputColumns, mapColumn));
    const [adjustValuesDialogIsOpen, setAdjustValuesDialogIsOpen] = React.useState(false);

    React.useEffect(() => {
        document.addEventListener('copy', handleCopy);
        document.addEventListener('paste', handlePaste);

        return () => {
            document.removeEventListener('copy', handleCopy);
            document.removeEventListener('paste', handlePaste);
        }
    }, [selectionRange]);

    React.useEffect(() => {
        setRows(inputRows);
    }, [inputRows]);

    React.useEffect(() => {
        setColumns(_.map(inputColumns, mapColumn));
    }, [inputColumns]);

    const interceptRightButtonDown = (e) => {
        if (e.button === 2) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const interceptContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setContextMenuCoordinates({
            x: e.clientX - 2,
            y: e.clientY - 4
        });
    }

    const handleContextMenuClose = () => {
        setContextMenuCoordinates({ x: null, y: null });
    }

    React.useEffect(() => {
        if (!gridRef?.current?.grid) return;

        gridRef.current.grid.addEventListener("mousedown", interceptRightButtonDown, { capture: true });
        gridRef.current.grid.addEventListener("contextmenu", interceptContextMenu, { capture: true });
    }, [gridRef]);

    const rowGetter = (rowNumber: number) => rows?.[rowNumber];
    const setSelection = (args) => {
        setSelectionRange({
            topLeft: {
                rowIdx: args.topLeft.rowIdx,
                colIdx: args.topLeft.idx,
            },
            botRight: {
                rowIdx: args.bottomRight.rowIdx,
                colIdx: args.bottomRight.idx,
            },
        });
    };

    const updateRows = (startIdx, newRows) => {
        const tempRows = rows.slice();
        for (let i = 0; i < newRows.length; i++) {
            if (startIdx + i < tempRows.length) {
                tempRows[startIdx + i] = { ...tempRows[startIdx + i], ...newRows[i] };
            }
        }
        setRows(tempRows);
    };

    const handleCopy = (e) => {
        e.preventDefault();
        const { topLeft, botRight } = selectionRange;

        // Loop through each row
        const text = _.range(topLeft.rowIdx, botRight.rowIdx + 1).map(
            // Loop through each column
            rowIdx => input.columns.slice(topLeft.colIdx, botRight.colIdx + 1).map(
                // Grab the row values and make a text string
                col => rowGetter(rowIdx)[col.key],
            ).join('\t'),
        ).join('\n');

        e.clipboardData.setData('text/plain', text);
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const { topLeft } = selectionRange;

        const newRows = [];
        const pasteData = defaultParsePaste(e.clipboardData.getData('text/plain'));

        pasteData.forEach((row) => {
            const rowData = {};
            // Merge the values from pasting and the keys from the columns
            input.columns.slice(topLeft.colIdx, topLeft.colIdx + row.length)
                .forEach((col, j) => {
                    // Create the key-value pair for the row
                    rowData[col.key] = row[j];
                });
            // Push the new row to the changes
            newRows.push(rowData);
        });

        updateRows(topLeft.rowIdx, newRows);
    };

    const onGridRowsUpdated = ({ fromRow, toRow, updated, action }) => {
        if (action !== 'COPY_PASTE') {
            const tempRows = rows.slice();
            for (let i = fromRow; i <= toRow; i++) {
                tempRows[i] = { ...tempRows[i], ...updated };
            }
            setRows(tempRows);
            name && updateParameterValue(name, tempRows);
        }
    };

    const getCellRangeTypes = (range: SelectionRange) => {
        if (!range?.topLeft || !range?.botRight) return [];

        const { topLeft, botRight } = range;
        return _.chain(input.columns.slice(topLeft.colIdx, botRight.colIdx + 1)).map(c => c.type).uniq().value();
    }

    const handleAdjustSelectedCellValues = () => {
        setAdjustValuesDialogIsOpen(true);
        handleContextMenuClose();
    };

    const getContextMenuItems = (selectionRange: SelectionRange) => {
        const result = [];
        const columnTypes = getCellRangeTypes(selectionRange);
        // Only numeric cells selected - show the % adjustment option...
        columnTypes?.length === 1 && columnTypes?.[0] === "number" && result.push(<MenuItem onClick={handleAdjustSelectedCellValues}>Adjust Values</MenuItem>);
        // No valid options available - show an appropriate message...
        result.length === 0 && result.push(<MenuItem disabled>No actions available for the selected cells.</MenuItem>);
        return result;
    }

    const adjustSelectedCellValues = (adjustmentPercentage: number) => {
        const { topLeft, botRight } = selectionRange;
        const adjustmentFactor = (100 + adjustmentPercentage) / 100;
        const tempRows = rows.slice();

        _.range(topLeft.rowIdx, botRight.rowIdx + 1).forEach(
            rowIdx => {
                let tempRow = { ...tempRows[rowIdx] };
                input.columns.slice(topLeft.colIdx, botRight.colIdx + 1).forEach(col => tempRow[col.key] = tempRow[col.key] * adjustmentFactor);
                tempRows[rowIdx] = tempRow;
            });

        setRows(tempRows);
        name && updateParameterValue(name, tempRows);
    }

    return (
        <div className="datagrid-container">
            <Menu
                keepMounted
                open={contextMenuCoordinates.y !== null}
                onClose={handleContextMenuClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenuCoordinates.y !== null && contextMenuCoordinates.x !== null
                        ? { top: contextMenuCoordinates.y, left: contextMenuCoordinates.x }
                        : undefined
                }
            >
                {getContextMenuItems(selectionRange)}
            </Menu>
            <AdjustValuesDialog isOpen={adjustValuesDialogIsOpen} setIsOpen={setAdjustValuesDialogIsOpen} adjustSelectedCellValues={adjustSelectedCellValues} />
            <ReactDataGrid
                ref={gridRef}
                columns={columns}
                rowGetter={rowGetter}
                rowsCount={input?.rows?.length || 0}
                onGridRowsUpdated={onGridRowsUpdated}
                enableCellSelect
                minColumnWidth={40}
                cellRangeSelection={{
                    onComplete: setSelection,
                }}
                headerRowHeight={40}
                onCellSelected={s => setSelectionRange({ topLeft: s, botRight: s })}
            />
        </div>
    )
}

export default connect(
    null,
    { updateParameterValue: updateParameterValue as any })(DataGrid);