import React from "react";
import { FeedbackOutlined } from "@material-ui/icons";
import { IconButton, Dialog, DialogContent, DialogContentText, TextField, DialogActions, Button, Tooltip, Avatar } from "@material-ui/core";
import DialogTitleWithCloseButton from "../../shared/components/DialogTitleWithCloseButton";
import { useForm } from 'react-hook-form';
import { useAuth0 } from "../../auth/AuthContext";
import * as api from "../../shared/api-client";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../store/notifications/actions";
import html2canvas from 'html2canvas';
import { logger } from "../services/loggingService";

export default function FeedbackWidget(props) {
    const validate = (values: { suggestedTargetAudience: string, positives: string, suggestions: string }) => {
        if (!values.suggestedTargetAudience && !values.positives && !values.suggestions) {
            return { form: "Please answer at least one question." };
        }

        return {};
    };
    const { register, handleSubmit, errors } = useForm({
        resolver: async (values: any) => {
            const errors = validate(values);

            return {
                values,
                errors
            };
        }
    });
    const [open, setOpen] = React.useState(false);
    const [screenshot, setScreenshot] = React.useState(null);
    const { user } = useAuth0();
    const dispatch = useDispatch();

    const openDialog = () => {

        setOpen(true);

        try {
            html2canvas(document.body, {
                foreignObjectRendering: false, ignoreElements: (el) => el?.tagName?.toLowerCase() === "noscript" || el?.className?.indexOf?.("MuiDialog-root") > -1 || el?.className?.indexOf?.("MuiDrawer-root") > -1 || el?.className?.indexOf?.("MuiTooltip-popper") > -1
            }).then(canvas => {
                const image = canvas.toDataURL("image/png");
                setScreenshot(image);
            });
        }
        catch (ex) {
            logger?.trackException?.({
                error: {
                    name: "Failed to capture screenshot",
                    message: ex
                }
            });
        }
    };

    const closeDialog = () => {
        setOpen(false);
    }

    const submitFeedback = (feedback) => {

        new api.FeedbackClient()
            .create({
                userName: user?.name || "Anonymous User",
                url: window.location.href,
                pageTitle: document.title,
                formData: JSON.stringify(feedback),
                screenshot
            })
            .then(() => {
                closeDialog();
                dispatch(showSuccess("Your feedback has been received.  Thank you!"));
            })
            .catch((error) => dispatch(showError("Failed to submit feedback.  Please try again.")));
    };

    return <>
        <Tooltip title="Submit Feedback About This Report" arrow>
            <IconButton id="btn-feedback" aria-controls="user-menu" aria-haspopup="true" onClick={openDialog} className="feedback-button">
                <Avatar className="bg-storychart-color-3">
                    <FeedbackOutlined fontSize="inherit" />
                </Avatar>
            </IconButton>
        </Tooltip>

        <Dialog open={open} onClose={closeDialog} disableBackdropClick={true} aria-labelledby="form-dialog-title">
            <DialogTitleWithCloseButton onClose={closeDialog} id="form-dialog-title">
                Submit Feedback
            </DialogTitleWithCloseButton>
            <form onSubmit={handleSubmit(submitFeedback)}>
                <DialogContent>
                    <DialogContentText>
                        Please submit your feedback and suggestions by answering one or more questions below.
                    </DialogContentText>
                    <br />
                    <TextField
                        name="suggestedTargetAudience"
                        error={!!errors.suggestedTargetAudience}
                        label="Who would this report be useful to?"
                        helperText={errors.suggestedTargetAudience?.message}
                        multiline
                        inputRef={register}
                        variant="outlined"
                        fullWidth
                        autoFocus
                    />
                    <TextField
                        name="positives"
                        error={!!errors.positives}
                        label="What is good about this report?"
                        helperText={errors.positives?.message}
                        multiline
                        inputRef={register}
                        variant="outlined"
                        fullWidth
                    />
                    <TextField
                        name="suggestions"
                        error={!!errors.suggestions}
                        label="What would you like to see improved?"
                        helperText={errors.suggestions?.message}
                        multiline
                        inputRef={register}
                        variant="outlined"
                        fullWidth
                    />
                    {
                        errors.form &&
                        <div className="form-errors">
                            {errors.form}
                        </div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="contained" color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    </>
}