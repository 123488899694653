import "./Map.scss";
import React from "react";
import Plot from "react-plotly.js";
import useResizeAware from 'react-resize-aware';
import { connect } from "react-redux";
import { updateParameterValue } from "../../../store/storyline/actions";
import { RootState } from "../../../store";
import MapControls from "./MapControls";
import * as _ from "lodash";

const style = { width: "100%", height: "100%", flexGrow: 1, flexShrink: 1, flexBasis: 1 };

const defaultMapboxAccessToken = "pk.eyJ1IjoiZGJhcnJldHRic2MiLCJhIjoiY2tjeW1zMWE1MGJlcjMxb3VwdHp1ejR3diJ9.NFsoLer0ez7eDYU-SpAY3Q";

interface ZoomOverride {
    center: {
        lat: number;
        lon: number;
    };
    zoom?: number;
}

interface DataPointHighlightOptions {
    markerSize: number;
    markerColor: string;
    dataPointFilter: Function;
}

interface Props {
    input: any,
    maxSelectionCount?: number,
    parameterName?: string,
    parameterValues: Map<string, any>,
    updateParameterValue: typeof updateParameterValue,
    onMaxSelected?: Function,
    onClick?: (event: Readonly<Plotly.PlotMouseEvent>) => void,
    staticPlot?: boolean,
    zoomOverride?: ZoomOverride,
    persistUserZoom?: boolean,
    dataPointHighlightOptions: DataPointHighlightOptions
}

function Map(props: Props) {
    // Just having the resize listener in the same div as the chart causes it to handle element resizing properly.  No need to explicitly call resize on the Plotly element...
    const [resizeListener] = useResizeAware();
    const { input, maxSelectionCount, parameterName, parameterValues, updateParameterValue, onMaxSelected, onClick, zoomOverride, persistUserZoom, dataPointHighlightOptions } = props;
    const { data, layout } = input || {};
    const [selectedItems, setSelectedItems] = React.useState([]);
    const [lastZoomDetails, setLastZoomDetails] = React.useState(null);
    const [mapStyle, setMapStyle] = React.useState(layout?.mapbox?.style || "light");
    const [mapData, setMapData] = React.useState([{
        lat: [],
        lon: [],
        mode: "markers",
        type: "scattermapbox"
    }] as any[]);
    const [mapLayout, setMapLayout] = React.useState(null);

    const dataPointClicked = React.useCallback(
        (data) => {
            if (!maxSelectionCount || !parameterName) return;

            if (data?.points?.[0]?.customdata) {
                const item = {
                    "id": data.points[0].customdata.id,
                    "name": data.points[0].customdata.name,
                    "lat": data.points[0].lat,
                    "lon": data.points[0].lon
                };

                const selectedItemsMinusThisPoint = selectedItems.filter(i => i.id !== item.id);

                // Remove item if it already exists in the list, otherwise add it...
                const newItems = selectedItemsMinusThisPoint.length !== selectedItems.length ?
                    selectedItemsMinusThisPoint :
                    [...selectedItems, item];

                const newItemsWithMaxLengthConstraint = newItems.slice(0, maxSelectionCount);

                setSelectedItems(newItemsWithMaxLengthConstraint);
                updateParameterValue(parameterName, newItemsWithMaxLengthConstraint);

                if (onMaxSelected && newItems.length === maxSelectionCount) {
                    onMaxSelected();
                }
            }
        }, [selectedItems, updateParameterValue, parameterName]);

    React.useEffect(() => {
        const newParameterValue = parameterValues.get(parameterName);
        if (!newParameterValue) return;
        setSelectedItems(newParameterValue);
    }, [parameterValues, parameterName]);

    const onRelayout = (e) => {
        persistUserZoom && setLastZoomDetails(e);
    };

    React.useEffect(() => {
        if (layout) {
            setMapLayout({
                ...layout,
                autosize: true,
                mapbox: {
                    ...layout?.mapbox,
                    center: lastZoomDetails?.["mapbox.center"] || layout?.mapbox?.center,
                    zoom: lastZoomDetails?.["mapbox.zoom"] || layout?.mapbox?.zoom,
                    style: mapStyle || layout?.mapbox?.style
                }
            });
        }
        else {
            setMapLayout({
                autosize: true,
                mapbox: {
                    center: lastZoomDetails?.["mapbox.center"] || { lon: 23.84474398368866, lat: -27.644883351235023 },
                    zoom: lastZoomDetails?.["mapbox.zoom"] || 4,
                    style: mapStyle,
                    accesstoken: defaultMapboxAccessToken
                },
                margin: {
                    r: 0,
                    t: 0,
                    l: 0,
                    b: 0
                }
            })
        }
    }, [layout, mapStyle, lastZoomDetails]);

    React.useEffect(() => {
        zoomOverride && setLastZoomDetails({
            "mapbox.center": zoomOverride.center,
            "mapbox.zoom": zoomOverride.zoom
        })
    }, [zoomOverride]);

    React.useEffect(() => {
        if (!data) return;

        const mapDataWithHighlighting = dataPointHighlightOptions ? data.map(layer => {
            const originalMarkerOptions = layer.originalMarker || layer.marker;

            const normalizedLayerData = _.zipWith(layer.lat, layer.lon, layer.customdata, (lat: number, lon: number, customdata: any) => ({ "lat": lat, "lon": lon, "customdata": customdata }));
            const markerOptions = originalMarkerOptions ? {
                ...originalMarkerOptions,
                "size": normalizedLayerData.map(point => dataPointHighlightOptions.dataPointFilter(point) ? (dataPointHighlightOptions.markerSize || originalMarkerOptions.size) : originalMarkerOptions.size),
                "color": normalizedLayerData.map(point => dataPointHighlightOptions.dataPointFilter(point) ? (dataPointHighlightOptions.markerColor || originalMarkerOptions.color) : originalMarkerOptions.color)
            } : undefined;

            return {
                ...layer,
                marker: markerOptions,
                originalMarker: originalMarkerOptions
            };
        }) : data;

        setMapData([
            ...mapDataWithHighlighting,
            {
                lat: selectedItems.map(i => i.lat),
                lon: selectedItems.map(i => i.lon),
                customdata: selectedItems.map(i => ({ id: i.id, name: i.name })),
                marker: {
                    color: "rebeccapurple",
                    size: 20,
                    symbol: "circle"
                },
                mode: "markers",
                name: "Selected Items",
                type: "scattermapbox",
                hoverinfo: "skip"
            }
        ]);

    }, [data, selectedItems, dataPointHighlightOptions, parameterValues]);

    return (
        <div className="fill map" style={{ position: "relative" }}>
            {!props.staticPlot && resizeListener}
            <Plot
                data={mapData}
                layout={mapLayout}
                config={{ displaylogo: false, responsive: true, autosizable: true, staticPlot: props.staticPlot || false, displayModeBar: false, doubleClick: false }}
                style={style}
                onClick={onClick || dataPointClicked}
                onRelayout={onRelayout}
            />

            <MapControls mapStyle={mapStyle} setMapStyle={setMapStyle} />
        </div>
    );
}

export default connect(
    (state: RootState) => ({
        parameterValues: state.storyline.parameterValues
    }),
    { updateParameterValue: updateParameterValue as any })(React.memo(Map));