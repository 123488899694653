import React from 'react';
import * as _ from "lodash";
import { StorylineState } from "../../../store/storyline/types";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { updateParameterValue, applyParameterValueChanges } from "../../../store/storyline/actions";

interface ParameterPersisterProps {
    parameters: string[],
    autoApplyAfterRestore: boolean,
    storyline: StorylineState,
    updateParameterValue: typeof updateParameterValue,
    applyParameterValueChanges: typeof applyParameterValueChanges
}

function ParameterPersister(props: ParameterPersisterProps) {

    const { parameters, autoApplyAfterRestore, updateParameterValue, applyParameterValueChanges, storyline } = props;

    const getPersistenceKey = (parameterName: string) => {
        return `${storyline.id}:${parameterName}`
    }

    React.useEffect(() => {
        let isDirty = false;

        _.forEach(parameters, p => {
            const previousRawValue = localStorage.getItem(getPersistenceKey(p));
            if (!previousRawValue) return;

            const previousValue = JSON.parse(previousRawValue);
            const currentValue = storyline.parameterValues.get(p);

            if (previousValue && !currentValue) {
                isDirty = true;
                updateParameterValue(p, previousValue);
            }
        });

        if (autoApplyAfterRestore && isDirty) {
            applyParameterValueChanges();
        }
    }, []);

    React.useEffect(() => {
        _.forEach(parameters, parameterName => {
            if (storyline.parameterValues.has(parameterName)) {
                const newValue = storyline.parameterValues.get(parameterName);
                if (newValue != null) {
                    localStorage.setItem(getPersistenceKey(parameterName), JSON.stringify(newValue));
                }
            }
        });
    }, [storyline.parameterValues]);

    return null;
}

export default connect(
    (state: RootState) => ({
        storyline: state.storyline
    }),
    { updateParameterValue: updateParameterValue as any, applyParameterValueChanges: applyParameterValueChanges as any })(ParameterPersister);