import React, { useState } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles(theme => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        flexGrow: 1,
        color: "white",
        backgroundColor: '#d32f2f',
        fontSize: "0.875rem",
        [theme.breakpoints.up('sm')]: {
            flexGrow: 'initial',
            minWidth: 320
        },
    },
    typography: {
        fontWeight: 'bold',
    },
    actionRoot: {
        padding: '10px 8px 10px 20px',
    },
    icons: {
        marginLeft: 'auto !important',
        color: "white !important"
    },
    expand: {
        padding: '4px',
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    collapse: {
        padding: 16,
        borderRadius: 0,
        fontSize: "0.875rem"
    },
    button: {
        padding: 0,
        textTransform: 'none',
    },
}));

const DetailedErrorSnackMessage = React.forwardRef((props: any, ref) => {
    const classes = useStyles();
    const { closeSnackbar } = useSnackbar();
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleDismiss = () => {
        closeSnackbar(props.id);
    };

    return (
        <Card className={classes.card} ref={ref}>
            <CardActions classes={{ root: classes.actionRoot }}>
                <HighlightOffIcon fontSize="small" />
                &nbsp;&nbsp;
                {props.message}
                &nbsp;&nbsp;
                <div className={classes.icons}>
                    <IconButton
                        size="small"
                        color="inherit"
                        aria-label="Show more"
                        className={classnames(classes.expand, { [classes.expandOpen]: expanded })}
                        onClick={handleExpandClick}
                    >
                        <ExpandMoreIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                        size="small"
                        color="inherit"
                        className={classes.expand}
                        onClick={handleDismiss}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </div>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit className="detailed-error-text">
                <Paper className={classes.collapse}>
                    <pre>{props.details || props.message}</pre>
                </Paper>
            </Collapse>
        </Card>
    );
});

export default DetailedErrorSnackMessage;