import React from 'react';
import * as _ from "lodash";
import { StorylineState } from "../../../store/storyline/types";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { updateParameterValue } from "../../../store/storyline/actions";
import MaterialTabs from '@material-ui/core/Tabs';

interface TabsProps {
    storyline: StorylineState,
    updateParameterValue: typeof updateParameterValue,
    name: string,
    defaultValue?: string,
    children: any
}

function Tabs(props: TabsProps) {

    const { storyline, updateParameterValue, name, defaultValue, children, ...other } = props;

    const parameterName = `tabs-${props.name}`;

    const [value, setValue] = React.useState(props.defaultValue);

    React.useEffect(() => {
        if (!props.storyline.parameterValues.has(parameterName)) {
            setValue(props.defaultValue);
            props.updateParameterValue(parameterName, props.defaultValue);
        }
    }, [props.defaultValue]);

    const handleChange = (event: any, newValue: string) => {
        setValue(newValue);
        props.updateParameterValue(parameterName, newValue);
    };

    React.useEffect(() => {
        if (props.storyline.parameterValues.has(parameterName)) {
            const newValue = props.storyline.parameterValues.get(parameterName);

            if (newValue !== value) {
                setValue(newValue);
            }
        }
    }, [props.storyline.parameterValues]);

    return (
        <MaterialTabs
            value={value}
            onChange={handleChange}
            {...other}
        >
            {_.filter(props.children, c => c.type.toString().indexOf("fragment") === -1)}
        </MaterialTabs>
    );
}

export default connect(
    (state: RootState) => ({
        storyline: state.storyline
    }),
    { updateParameterValue: updateParameterValue as any })(Tabs);