import "./Annotations.scss";
import React from "react";
import * as _ from "lodash";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import TextToSpeechPlayer from "../TextToSpeechPlayer";

export default function Annotations(props) {
    let items = props.input || [];
    return items && items.length && _.find(items, i => !!i) ? (
        <Card className="annotations col-fixed">
            <CardHeader title="Comments" action={<TextToSpeechPlayer text={items} />} />
            <CardContent>
                {_.map(items, (text, i) => <div className="item" dangerouslySetInnerHTML={{ __html: text }} key={i} ></div>)}
            </CardContent>
        </Card>
    ) : <></>;
}