import React from 'react';
import "./RadioGroup.scss";
import { StorylineState } from "../../../store/storyline/types";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { updateParameterValue } from "../../../store/storyline/actions";
import { RadioGroup as MaterialRadioGroup, Radio as MaterialRadio, FormControlLabel } from '@material-ui/core';

interface RadioGroupProps {
    storyline: StorylineState;
    updateParameterValue: typeof updateParameterValue;
    name: string;
    [key: string]: any;
}

function _RadioGroup(props: RadioGroupProps) {
    const { storyline, updateParameterValue, name, children } = props;
    const [value, setValue] = React.useState(storyline.parameterValues.get(name) || 0);

    const handleChange = (event: any) => {
        updateParameterValue(name, event.target.value);
        setValue(event.target.value);
    };

    React.useEffect(() => {

        if (storyline.parameterValues.has(name)) {
            const newValue = storyline.parameterValues.get(name);
            if (newValue !== value) {
                setValue(newValue);
            }
        }

    }, [storyline.parameterValues]);

    return (
        <MaterialRadioGroup
            className="radio-group"
            aria-label={name}
            name={name}
            value={value}
            onChange={handleChange}
        >
            {children}
        </MaterialRadioGroup>
    );
}

interface RadioProps {
    value: string;
    label: string;
}

export function Radio(props: RadioProps) {
    const { value, label } = props;
    return <FormControlLabel value={value} control={<MaterialRadio color="primary" />} label={label} />;
}

export const RadioGroup = connect(
    (state: RootState) => ({
        storyline: state.storyline
    }),
    { updateParameterValue: updateParameterValue as any })(_RadioGroup);