import React from "react";
import { Button, Container, Card, Select, MenuItem } from "@material-ui/core";
import * as _ from "lodash";
import { useForm, Controller } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from "@hookform/resolvers";
import { connect } from "react-redux";
import { showSuccess, showError } from "../../store/notifications/actions";
import NavBar from "./NavBar";
import { getUserPreferences, setUserPreferences } from "../services/userPreferencesService";

interface UserPreferencesProps {
    showSuccess: typeof showSuccess,
    showError: typeof showError
}

const animationOptions = [{ value: "none", label: "No Animations" }, { value: "slide", label: "Slide In/Out" }, { value: "flip", label: "Page Turn" }];
const voiceOptions = [
    {
        value: {
            languageCode: "en-US",
            name: "en-US-Wavenet-C"
        },
        label: "US - Female 1"
    }, {
        value: {
            languageCode: "en-US",
            name: "en-US-Wavenet-E"
        },
        label: "US - Female 2"
    }, {
        value: {
            languageCode: "en-US",
            name: "en-US-Wavenet-F"
        },
        label: "US - Female 3"
    }, {
        value: {
            languageCode: "en-US",
            name: "en-US-Wavenet-A"
        },
        label: "US - Male 1"
    }, {
        value: {
            languageCode: "en-US",
            name: "en-US-Wavenet-B"
        },
        label: "US - Male 2"
    }, {
        value: {
            languageCode: "en-US",
            name: "en-US-Wavenet-D"
        },
        label: "US - Male 3"
    }, {
        value: {
            languageCode: "en-GB",
            name: "en-GB-Wavenet-A"
        },
        label: "GB - Female 1"
    }, {
        value: {
            languageCode: "en-GB",
            name: "en-GB-Wavenet-C"
        },
        label: "GB - Female 2"
    }, {
        value: {
            languageCode: "en-GB",
            name: "en-GB-Wavenet-B"
        },
        label: "GB - Male 1"
    }, {
        value: {
            languageCode: "en-GB",
            name: "en-GB-Wavenet-D"
        },
        label: "GB - Male 2"
    }, {
        value: {
            languageCode: "en-AU",
            name: "en-AU-Wavenet-A"
        },
        label: "AU - Female 1"
    }, {
        value: {
            languageCode: "en-AU",
            name: "en-AU-Wavenet-C"
        },
        label: "AU - Female 2"
    }, {
        value: {
            languageCode: "en-AU",
            name: "en-AU-Wavenet-B"
        },
        label: "AU - Male 1"
    }, {
        value: {
            languageCode: "en-AU",
            name: "en-AU-Wavenet-D"
        },
        label: "AU - Male 2"
    }
]

function UserPreferences(props: UserPreferencesProps) {
    const schema = object().shape({
        slideTransitionAnimationStyle: string().required("Please select a slide transition animation style."),
        voice: object().required("Please select a Narration Voice.")
    });
    let preferences = {
        ...getUserPreferences()
    };
    preferences.voice = _.find(voiceOptions, vo => vo.value.name === preferences?.voice?.name)?.value;
    const { handleSubmit, errors, control } = useForm({ resolver: yupResolver(schema), defaultValues: preferences});

    const save = (formModel) => {
        setUserPreferences(formModel);
        props.showSuccess("Your preferences have been saved successfully.");
    };

    return (
        <>
            <NavBar title="User Preferences" />

            <Container className="plush" maxWidth="sm">
                <Card className="plush">
                    <form onSubmit={handleSubmit(save)} className="d-flex flex-column" style={{ minHeight: "200px" }}>
                        <div className="flex-fill">
                            <div className="select-header">Slide Transition Animation Style:</div>
                            <Controller
                                as={
                                    <Select
                                        variant="outlined"
                                        error={!!errors.slideTransitionAnimationStyle}
                                        fullWidth>
                                        {
                                            animationOptions.map(o => <MenuItem value={o.value}>{o.label}</MenuItem>)
                                        }
                                    </Select>
                                }
                                control={control}
                                name="slideTransitionAnimationStyle"
                            />
                            <br />
                            <br />
                            <div className="select-header">Narration Voice:</div>
                            <Controller
                                as={
                                    <Select
                                        variant="outlined"
                                        error={!!errors.voice}
                                        fullWidth>
                                        {
                                            voiceOptions.map(o => <MenuItem value={o.value as any}>{o.label}</MenuItem>)
                                        }
                                    </Select>
                                }
                                control={control}
                                name="voice"
                            />
                            <br />
                            <br />
                            <br />
                        </div>
                        <Button type="submit" variant="contained" color="primary">
                            Save Preferences
                    </Button>
                    </form>
                </Card>
            </Container>
        </>
    );
}

export default connect(
    null,
    { showSuccess, showError })(UserPreferences);