import React from "react";
import "./NavBar.scss";
import { AppBar, Toolbar, Typography, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { toggleMenu } from "../../store/app/actions";
import { Menu as MenuIcon } from '@material-ui/icons';
import { useSettings } from "../../shared/providers/SettingsProvider";
import { Helmet } from "react-helmet";

interface Props {
    toggleMenu: typeof toggleMenu;
    title: string;
    actions?: any;
    children?: any;
}

export function NavBar(props: Props) {
    const { toggleMenu, title, actions, children } = props;
    const settings = useSettings();

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <AppBar position="sticky" className="navbar">
                <Toolbar>
                    <IconButton
                        id="btn-toggle-menu"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleMenu}>
                        <MenuIcon />
                    </IconButton>

                    <img src="/logo-white-text.png" alt="StoryChart Logo" className="navbar-logo" />

                    <Typography variant="h6" className="slide-title">
                        {title}
                    </Typography>

                    {children}

                    {settings.logoUrl && <img src={settings.logoUrl} alt="Company Logo" className="tenant-logo" />}

                    {actions}
                </Toolbar>
            </AppBar>
        </>
    );
}

export default connect(
    null,
    { toggleMenu })(NavBar);