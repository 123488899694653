import React from 'react';
import "./Table.scss";
import GridFooter from "./GridFooter";
import NoRowsOverlay from "./NoRowsOverlay";
import { StorylineState } from "../../../store/storyline/types";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { updateParameterValue, goToID, applyParameterValueChanges } from "../../../store/storyline/actions";
import { DataGridPro, GridColDef, GridRowData, GridRowParams } from '@mui/x-data-grid-pro';
import * as _ from "lodash";
import moment from "moment";

interface ParameterMap {
    fieldName: string;
    parameterName: string;
}

interface AdditionColumnFields extends GridColDef {
    index: number;
}

interface TableProps {
    storyline: StorylineState;
    parameterMap?: ParameterMap[];
    rows: GridRowData[];
    columns: GridColDef[];
    additionalColumns: AdditionColumnFields[];
    navigateTo?: string;
    selectedRowParameter?: string;
    updateParameterValue: typeof updateParameterValue;
    className?: string;
    onRowClick?: (param: GridRowParams) => void;
    hideExportToExcel?: boolean;
}

function Table(props: TableProps) {
    const { storyline, parameterMap, selectedRowParameter, updateParameterValue, navigateTo, rows, columns, additionalColumns, className, ...other } = props;
    const [allColumns, setAllColumns] = React.useState(columns);

    React.useEffect(() => {
        const result = columns;
        _.forEach(additionalColumns, additionalColumn => {
            columns.splice(additionalColumn.index, 0, additionalColumn);
        });
        setAllColumns(result);

    }, [columns, additionalColumns]);

    const onRowClick = (e: GridRowParams) => {
        const data = e.row;

        if (parameterMap?.length && navigateTo) {
            const parameters = _.map(parameterMap, map => `${encodeURIComponent(map.parameterName)}=${encodeURIComponent(data[map.fieldName])}`);

            window.open(`${navigateTo}?${parameters.join("&")}`, "_blank");
        }
        else if (selectedRowParameter) {
            updateParameterValue(selectedRowParameter, data);
        }

        return () => null;
    };

    return (
        <DataGridPro 
            {...other}
            className={`table-component ${navigateTo ? "navigate-on-click" : ""} ${className} `}
            components={{
                NoRowsOverlay: NoRowsOverlay,
                Footer: GridFooter
            }}
            componentsProps={{
                footer: { hideExportToExcel: props.hideExportToExcel }
            }}
            columns={allColumns}
            rows={rows || []}
            onRowClick={props.onRowClick || onRowClick} />
    );
}

export default connect(
    (state: RootState) => ({
        storyline: state.storyline
    }),
    { updateParameterValue: updateParameterValue as any, goToID: goToID as any, applyParameterValueChanges: applyParameterValueChanges as any })(Table);


/// Higher-order wrapper around Intl.NumberFormat object that allows us to use it within template bindings...
export function getColumnNumberFormatter(decimalPlaces = 2, prefix = "", suffix = "", multiplicationFactor = 1) {
    const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces
    } as Intl.NumberFormatOptions);

    return (params) => `${prefix}${formatter.format(params.value * multiplicationFactor)}${suffix}`;
}

/// Higher-order wrapper around moment.format that allows us to use it within template bindings...
export function getColumnDateTimeFormatter(formatString = "YYYY-MM-DD HH:mm") {
    return (params) => moment(params?.value).format(formatString);
}