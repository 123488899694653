import React from "react";

interface TabPanelProps {
  children: any;
  index: any;
  value: any;
}

export default function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      className="tabpanel"
      {...other}
    >
      {value === index && (
        <>{children}</>
      )}
    </div>
  );
}