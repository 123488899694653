import React from "react";
import {
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";
import { connect } from "react-redux";
import { toggleMenu } from "../store/app/actions";
import { RootState } from "../store";
import { AppState } from "../store/app/types";
import Users from "./Users/Users";

interface AdminProps {
    app: AppState
}

function Admin(props: AdminProps) {
    let match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}/users`}>
                <Users />
            </Route>
        </Switch>
    );
}

export default connect(
    (state: RootState) => ({
        app: state.app,
        storyline: state.storyline
    }),
    { toggleMenu })(Admin);