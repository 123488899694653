import "./Gantt.scss";
import React from "react";
import TimeLine from "react-gantt-timeline";
import { Button, Dialog, DialogContent, TextField, DialogActions, Tooltip, Fab } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { KeyboardDatePicker } from '@material-ui/pickers';
import DialogTitleWithCloseButton from "../../../shared/components/DialogTitleWithCloseButton";
import { useForm, Controller } from "react-hook-form";
import { object, string, date } from 'yup';
import { yupResolver } from "@hookform/resolvers";
import * as _ from "lodash";

const DatePicker = KeyboardDatePicker as any; // Wrapping the normal DatePicker in a Controller injects value, onChange and onBlur, but TS doesn't recognize these.  Downcast the component to any in order to get it to compile...

interface Task {
    id?: string,
    name: string,
    start: Date,
    end: Date
}

interface EditTaskProps {
    isOpen: boolean,
    setIsOpen: (boolean) => void,
    task?: Task,
    onSave: (task: Task) => void,
    onDelete: (task: Task) => void
}

function EditTaskDialog(props: EditTaskProps) {
    const schema = object().shape({
        name: string()
            .required("This field is required."),
        start: date()
            .required("Start Date is required."),
        end: date()
            .required("End Date is required.")
    });
    const { register, handleSubmit, errors, reset, control, getValues } = useForm({ resolver: yupResolver(schema) });

    React.useEffect(() => {
        if (!props.isOpen) return;

        reset(props.task || {});
    }, [props.isOpen]);

    const closeDialog = () => {
        props.setIsOpen(false);
    }

    const save = (formModel: Task) => {
        props.onSave({ ...props.task, ...formModel });
        closeDialog();
    };

    const deleteTask = () => {
        props.onDelete(props.task);
        closeDialog();
    }

    return (
        <Dialog open={props.isOpen} onClose={closeDialog} disableBackdropClick={true} aria-labelledby="form-dialog-title">
            <DialogTitleWithCloseButton onClose={closeDialog} id="form-dialog-title">
                {`${props?.task?.id ? "Edit Item" : "Create Item"}`}
            </DialogTitleWithCloseButton>
            <form onSubmit={handleSubmit(save)}>
                <DialogContent>
                    <TextField
                        name="name"
                        error={!!errors.name}
                        label="Name"
                        helperText={errors.name?.message}
                        inputRef={register}
                        variant="outlined"
                        fullWidth
                        autoFocus
                    />
                    <Controller
                        name="start"
                        control={control}
                        defaultValue={getValues().start || null}
                        initialFocusedDate={getValues().start || null}
                        as={
                            <DatePicker
                                style={{ width: "100%" }}
                                name="start"
                                error={!!errors.start}
                                label="Start Date"
                                helperText={errors.start && "Start Date is required."}
                                variant="inline"
                                inputVariant="outlined"
                                format="DD/MM/yyyy"
                                KeyboardButtonProps={{
                                    "aria-label": "Change Date",
                                }}
                            />
                        }
                    />
                    <Controller
                        name="end"
                        control={control}
                        defaultValue={getValues().end || null}
                        initialFocusedDate={getValues().end || null}
                        as={
                            <DatePicker
                                style={{ width: "100%", marginTop: "16px" }}
                                name="end"
                                error={!!errors.end}
                                label="End Date"
                                helperText={errors.end && "End Date is required."}
                                variant="inline"
                                inputVariant="outlined"
                                format="DD/MM/yyyy"
                                KeyboardButtonProps={{
                                    "aria-label": "Change Date",
                                }}
                            />
                        }
                    />
                </DialogContent>
                <DialogActions>
                    {
                        props?.task?.id &&
                        <Button type="button" variant="contained" className="button-delete" color="secondary" style={{ marginRight: "20px" }} onClick={deleteTask}>
                            Delete Item
                            </Button>
                    }

                    <Button type="submit" variant="contained" color="primary">
                        {`${props?.task?.id ? "Update" : "Add"} Item`}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

const config = {
    header: {
        top: {
            style: {

            }
        },
        middle: {
            style: {

            }
        },
        bottom: {
            style: {
                background: "#72a230"
            },
            selectedStyle: {
                backgroundColor: "#ed1c24"
            }
        }
    },
    taskList: {
        title: {
            label: "Project"
        }
    },
    dataViewPort: {
        task: {
            showLabel: true,
            style: {
                backgroundColor: "#2B60A2"
            },
            selectedStyle: {
                backgroundColor: "#2B60A2"
            }
        }
    }
};

export default function Gantt(props: any) {
    const { input, onUpdate } = props;
    const [items, setItems] = React.useState(input);
    const [selectedItem, setSelectedItem] = React.useState(null);
    const [editDialogIsOpen, setEditDialogIsOpen] = React.useState(false);

    const onEditTaskComplete = (task: Task) => {
        if (!task.id) {
            task.id = new Date().toISOString();
            setItems([...items, task]);
        }
        else {
            const item = _.find(items, i => i.id === task.id);
            item.name = task.name;
            item.start = task.start;
            item.end = task.end;

            setItems([...items]);
        }
    }

    const onDeleteTask = (task: Task) => {
        setItems(_.filter(items, i => i.id !== task.id));
    }

    const onUpdateTask = (item, updatedTask) => {

        item.start = updatedTask.start || item.start;
        item.end = updatedTask.end || item.end;
        item.name = updatedTask.name || item.name;

        setItems([...items]);

        if (onUpdate) {
            onUpdate(items);
        }
    };

    const onSelectTask = (item) => {
        if (item === selectedItem) {
            setEditDialogIsOpen(true);
        }
        else {
            setSelectedItem(item);
        }
    }

    return (
        <>
            <div className="col-fill gantt">
                <TimeLine
                    {...props}
                    data={items}
                    config={config}
                    itemheight={props.itemHeight || 36}
                    mode={props.mode || "year"}
                    onUpdateTask={!props.readOnly && onUpdateTask}
                    onSelectItem={!props.readOnly && onSelectTask}
                    nonEditableName={props.readOnly}
                />

                {
                    !props.readOnly &&
                    <Tooltip title="Add Item" arrow>
                        <Fab color="secondary" className="button-add" onClick={() => { setSelectedItem(null); setEditDialogIsOpen(true); }}>
                            <Add />
                        </Fab>
                    </Tooltip>
                }

            </div>


            <EditTaskDialog
                task={selectedItem}
                isOpen={editDialogIsOpen}
                setIsOpen={setEditDialogIsOpen}
                onSave={onEditTaskComplete}
                onDelete={onDeleteTask}
            />
        </>
    );
}