import React from "react";
import { Menu } from "@material-ui/icons";
import Storyline from "./Storyline/Storyline";
import Home from "./Home/Home";
import * as _ from "lodash";
import {
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import { connect } from "react-redux";
import { IconButton, CircularProgress } from "@material-ui/core";
import { toggleMenu } from "../store/app/actions";
import { loadStoryline, loadCanvas } from "../store/storyline/actions";
import { RootState } from "../store";
import { AppState } from "../store/app/types";
import AppInsightsErrorBoundary from "../shared/components/AppInsightsErrorBoundary";
import ErrorPage from "../shared/components/Error";

interface ViewerProps {
    app: AppState,
    parameterValues: Map<string, any>,
    loadStoryline: typeof loadStoryline,
    loadCanvas: typeof loadCanvas,
    toggleMenu: typeof toggleMenu
}

function Viewer(props: ViewerProps) {
    const { parameterValues } = props;
    const visibilityClasses = [
        parameterValues.get("isExport") == 1 ? "is-export" : undefined,
        parameterValues.get("hideTitleBar") == 1 ? "hide-titlebar" : undefined,
        parameterValues.get("hideMinimap") == 1 ? "hide-minimap" : undefined,
        parameterValues.get("hideCommentary") == 1 ? "hide-commentary" : undefined
    ];

    return (
        <>
            <IconButton className="hamburger-icon" onClick={props.toggleMenu}><Menu /></IconButton>

            <div className={`content ${visibilityClasses.filter(a => !!a).join(" ")}`}>
                {
                    props.app.loading ?
                        <CircularProgress className="loading-spinner" /> :
                        <Switch>
                            <Route exact path="/" render={() => {
                                const directLinks = _.chain(props.app.menuItems).flatMapDeep(mi => [mi, ...mi.children]).filter(mi => !mi?.children?.length).value();

                                return directLinks.length !== 1 ?
                                    <Home /> :
                                    <Redirect to={directLinks[0].url} />
                            }} />
                            <Route path={`/storylines/:id`} render={({ match }) =>
                                <AppInsightsErrorBoundary key={match.url} onError={() => <ErrorPage />}>
                                    <Storyline id={match.params.id} loadStoryline={props.loadStoryline} />
                                </AppInsightsErrorBoundary>
                            } />

                            <Route path={`/canvases/:id`} render={({ match }) =>
                                <AppInsightsErrorBoundary key={match.url} onError={() => <ErrorPage />}>
                                    <Storyline id={match.params.id} loadStoryline={props.loadCanvas} />
                                </AppInsightsErrorBoundary>
                            } />
                        </Switch>
                }
            </div>
        </>
    );
}

export default connect(
    (state: RootState) => ({
        app: state.app,
        parameterValues: state.storyline.parameterValues
    }),
    { loadStoryline: loadStoryline as any, loadCanvas: loadCanvas as any, toggleMenu })(Viewer);