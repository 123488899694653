import React, {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  Ref,
} from "react";
import { GoogleMap, DrawingManager } from "@react-google-maps/api";
import { GeoMarker } from "./marker";
import { GeoPolygon } from "./polygon";
import { GeoMarkerModel, GeoPolygonModel, LatLng } from "./services/types";
import { DataState } from "./services/enums";
import { v4 as uuidv4 } from "uuid";
import { polygonOptions } from "./services/constants";

interface MapSettings {
  center: google.maps.LatLngLiteral;
  zoom: number;
}

const containerStyle = {
  width: "100%",
  height: "100%",
};

export interface GeoMapViewComponent {
  saveSettings: () => void;
  getBounds: () => number[][] | null;
}
export interface GeoMapProps {
  zoom?: number;
  markers: GeoMarkerModel[];
  polygons: GeoPolygonModel[];
  zoomToBounds: number[][];
  onPolygonAdded: (polygon: GeoPolygonModel) => void;
  onPolygonUpdated: (polygon: GeoPolygonModel) => void;
  onPolygonDeleted: (id: string) => void;
}

export const GeoMap = React.forwardRef(
  (props: GeoMapProps, ref: Ref<GeoMapViewComponent>) => {
    const { zoomToBounds } = props;
    const mapRef = useRef<google.maps.Map | null>();
    const [settings, setSettings] = useState<MapSettings>();

    const mapOptions: google.maps.MapOptions = {
      streetViewControl: false,
      rotateControl: false,
      mapTypeId: google.maps.MapTypeId.HYBRID,
    };

    useEffect(() => {
      const settingValue = loadSettings();
      setSettings(settingValue);

      return () => {
        saveSettings();
      };
    }, []);

    const performZoomToBounds = (map, zoomToBounds: number[][]) => {
      if (!zoomToBounds || !zoomToBounds?.length || !zoomToBounds?.[0]?.length)
        return;
      if (!map) return;

      const bounds = new google.maps.LatLngBounds();
      for (const bound of zoomToBounds) {
        bounds.extend(new google.maps.LatLng(bound?.[1], bound?.[0]));
      }
      map.fitBounds(bounds);
    };

    useEffect(() => {
      performZoomToBounds(mapRef?.current, zoomToBounds);
    }, [zoomToBounds, mapRef]);

    useImperativeHandle(ref, () => ({
      saveSettings,
      getBounds,
    }));

    function loadSettings(): MapSettings {
      let settingData = localStorage.getItem("map_settings");
      let settingValue: MapSettings;

      if (settingData) {
        settingValue = JSON.parse(settingData);
      } else {
        settingValue = {
          center: { lat: -26.312366461907562, lng: 28.133224756244665 },
          zoom: 15,
        };
        localStorage.setItem("map_settings", JSON.stringify(settingValue));
      }

      return settingValue;
    }

    function saveSettings() {
      if (!mapRef.current) return;
      const bounds = mapRef.current.getBounds() as google.maps.LatLngBounds;
      const center = bounds.getCenter().toJSON();
      const zoom = mapRef.current.getZoom();

      const settingValue = { center, zoom };
      localStorage.setItem("map_settings", JSON.stringify(settingValue));
    }

    function getBounds() {
      if (!mapRef.current) return null;

      const bounds = mapRef.current.getBounds() as google.maps.LatLngBounds;
      if (!bounds) return null;

      return convertBoundsToPoints(bounds);
    }

    function handleLoad(map: google.maps.Map) {
      mapRef.current = map;
      performZoomToBounds(map, zoomToBounds);
    }

    function handleUnmount() {
      saveSettings();

      mapRef.current = null;
    }

    function handlePolygonUpdated(polygon: GeoPolygonModel) {
      props.onPolygonUpdated(polygon);
    }

    function handlePolygonDeleted(id: string) {
      props.onPolygonDeleted(id);
    }

    function convertMarkerPosition(marker: GeoMarkerModel): LatLng {
      return { lat: marker.lat, lng: marker.lng };
    }

    function handlePolygonComplete(polygon: google.maps.Polygon) {
      // Remove the polygon from the map. It will be added to the map using React
      polygon.setMap(null);

      const path = polygon
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });

      const polygonModel: GeoPolygonModel = {
        id: uuidv4(),
        name: "New",
        site: "New",
        poiType: "New",
        tat: "New",
        minTat: "20",
        lane: "New",
        path: path,
        dataState: DataState.added,
      };

      props.onPolygonAdded(polygonModel);
    }

    function convertBoundsToPoints(
      bounds: google.maps.LatLngBounds
    ): number[][] {
      const sw = bounds.getSouthWest();
      const ne = bounds.getNorthEast();

      return [
        [sw.lng(), sw.lat()],
        [sw.lng(), ne.lat()],
        [ne.lng(), ne.lat()],
        [ne.lng(), sw.lat()],
      ];
    }

    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        position: google.maps.ControlPosition.BOTTOM_LEFT,
      },
      polygonOptions: polygonOptions,
    };

    return (
      <GoogleMap
        onLoad={handleLoad}
        onUnmount={handleUnmount}
        mapContainerStyle={containerStyle}
        center={settings?.center}
        options={mapOptions}
        zoom={settings?.zoom}
      >
        {props.markers?.map((m) => (
          <GeoMarker
            key={m.id}
            title={m.name}
            color={m.color}
            size={m.size}
            position={convertMarkerPosition(m)}
          />
        ))}
        {props.polygons?.map((obj) => (
          <GeoPolygon
            key={obj.id}
            model={obj}
            onUpdated={handlePolygonUpdated}
            onDeleted={handlePolygonDeleted}
          />
        ))}

        <DrawingManager
          options={options}
          onPolygonComplete={handlePolygonComplete}
        />
      </GoogleMap>
    );
  }
);
