import React from 'react';
import { StorylineState } from "../../../store/storyline/types";
import { connect } from "react-redux";
import { RootState } from "../../../store";

interface TabPanelProps {
    storyline: StorylineState,
    tabsName: string,
    value: string,
    children: any
}

function TabPanel(props: TabPanelProps) {
    const { storyline, tabsName, value, children, ...other } = props;
    const parameterName = `tabs-${tabsName}`;
    const [activeTab, setActiveTab] = React.useState(storyline.parameterValues.get(parameterName));

    React.useEffect(() => {
        if (storyline.parameterValues.has(parameterName)) {
            const newValue = storyline.parameterValues.get(parameterName);

            if (newValue !== activeTab) {
                setActiveTab(newValue);
            }
        }
    }, [storyline.parameterValues]);

    return activeTab === value &&
        <div {...other}>
            {children}
        </div>;
}

export default connect(
    (state: RootState) => ({
        storyline: state.storyline
    }),
    null)(TabPanel);